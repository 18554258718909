import * as React from 'react';
import styled from "styled-components";
import { convertDate } from '../../../utils/date';
import Tag from "../../../components/Tag/Tag.component";

const Row = styled.tr`
     height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
`;

const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100px * 5);
    padding-top: 15px;
    padding-bottom: 15px;  
`;

const NameTd = styled.td`
    text-align: left;
    padding-left: 10px;
    font-weight: bolder;
    font-size: 16px;
`;

interface Props {   
    id: string;
    name: string;
    status: string;
    actions: string;
    actionsList: { id: string; name: string }[] | null;
    createdAt: string;    
    onEdit?: React.MouseEventHandler;
    onDelete?: React.MouseEventHandler;
}
const WorkflowTableIteam: React.FunctionComponent<Props> = ({ id, name, status, actionsList, createdAt, onEdit,  onDelete }) => {
    return <Row key={id}>
            <NameTd> { name } </NameTd>
            <td> { status } </td>            
            <td>  { actionsList ? <TagContainer>{actionsList.map((item)=> <Tag key={item.id} title={item.name} />)}</TagContainer> :  'No action is added' }</td>
            <td>{  convertDate(createdAt) }</td>
            <td>                
                <span onClick={onEdit} >Edit</span>&nbsp;&nbsp;
                <span onClick={onDelete} >Delete</span>
            </td>
        </Row>
}

export default WorkflowTableIteam;