import { apiClient } from "../infrastructure/api/apiClient";

export const getWorkflows = () => {
  return apiClient.get('/workflow');
};

export const getWorkflowDetails = (id:string) => {
  return apiClient.get(`/workflow/details/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const createWorkflow = (payload: any) => {
    return apiClient.post('/workflow/create', payload);
};

export const updateWorkflow = (id:string, payload: any) => {
  return apiClient.put(`/workflow/${id}`, payload)
  .then((response)=> {    
    return response.data;
  });
};

export const deleteWorkflow = (id:string) => {
  return apiClient.delete(`/workflow/${id}`)
  .then((response)=> {    
    return response.data;
  });
};