import styled from 'styled-components';
import AcceptImage from "../../assets/svg/gray/accept.png";


const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Message = styled.div`
    margin-top: 10px;
`;


const Image = styled.img`
    display: flex;
    flex-direction: column;
    width: 70px;
`;

interface ProfileProps{
    title: string;
 }

function SentComponent ({title}: ProfileProps) {

    return (<>
        <Wrapper>
            <Image src={AcceptImage}  />
            <Message>{ title }</Message>
        </Wrapper>
  </>);
}


export default SentComponent;

