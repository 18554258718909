import styled from "styled-components";
import * as React from "react";

const SearchInput = styled.input`
    width: 200px;
    border: 1px solid #f2edf2;
    padding: 8px;
    border-radius: 0;
    &:focus {
        width: 300px;
        outline: none;
    }
`;

export const EmptySearch = styled.div`
    width: 200px;
`;

const SearchBoxComponent = ({ placeholder })=> {

    return (<>
        <SearchInput placeholder={placeholder}/>
    </>);
};

export default SearchBoxComponent;
