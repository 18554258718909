import './styles/workflow.style.css';
import { useState } from 'react';
import TabComponent from '../../../components/Tab/Tab.component';
import WorkflowsFeature from '../workflow/workflows.feature';
import { TabsEnum } from '../workflow/workflow-table.feature';
import ActionsFeature from '../action/actions.feature';

const tabItems = [
    {
      id: 1,
      title: 'Workflows',
      content: <WorkflowsFeature tabType = { TabsEnum.WORKFLOW } title="Workflow" showSearch={false} showControls={true} />,
    },
    {
      id: 2,
      title: 'Actions',
      content: <ActionsFeature title="Actions" tabType = { TabsEnum.ACTION } showSearch={false} showControls={true} />,
    }
  ];

interface Props {
    title?: string;
}

const WorkflowTabFeature: React.FunctionComponent<Props> = ({ title }) => {
    const [active, setActive] = useState(1);
    return <>
        <div className="tabs">
        {tabItems.map(({ id, title }) =><TabComponent
           key={id}
           title={title}
           onItemClicked={() => setActive(id)}
           isActive={active === id}
         />
      )}
      </div>
      <div className="content">
        {tabItems.map(({ id, content }) => {
          return active === id ?  <div key={id}>{content}</div> : '';
        })}
      </div>
    </>;
};
  

export default WorkflowTabFeature;