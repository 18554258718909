import { useState } from "react";
import styled from "styled-components";
import InputTextComponent from "../../../../components/input/InputText.component";
import SentComponent from "../../../../components/Sent/Sent.component";
import { startOffboarding } from "../../../../services/onboardings.service";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    employeeId?: string;
 }

const StartOffboardingFeature:React.FC<InputTextProps> = ({ title, employeeId })=> {
    const [notSent, setnotSent] = useState(true);

    const handleSubmit = async ()=> {
        try {
        setnotSent(false);
        await startOffboarding({ 
            userId: employeeId,
            fromDate: fromDate
         });
        } catch (e) {
            setnotSent(true);
        }
    }

    
    const [fromDate, setFromDate] = useState("");
  
    const handleDate = (event: any) => {
        setFromDate(event.target.value);
    };

  return <> 
    {title}
    {notSent ? <div>
        <FormItem>
        <InputTextComponent label="Start Date" onChildStateChange = {handleDate} fullWidth={true} type="date"  placeholder="Choose Date" />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() } >Process</Button>
    </FormItem></div> : <SentComponent title="Employee invitation has been sent!" /> }
  </>
};

export default StartOffboardingFeature;
