import './styles/boarding-tab.style.css';
import { TabsEnum } from '../onboarding/onboarding-table.feature';
import EmployeeFeature from '../../Employee/employee.feature';

interface Props {
    title?: string;
}

const EmployeeTabFeature: React.FunctionComponent<Props> = () => {
    return <>
      <div className="content">
        <EmployeeFeature tabType = { TabsEnum.EMPLOYED } title="Add Employee"  showSearch={false} showControls={true} />
      </div>
    </>;
};


export default EmployeeTabFeature;
