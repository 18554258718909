import { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../../../components/DropdownSelectBox/DropdownSelectBox.component";
import SentComponent from "../../../../components/Sent/Sent.component";
import { createTeamMembership } from '../../../../services/team.service';
import Button from '../../../../components/Buttons/Button.component';
import { arrayDifference, mapToOption, Employee } from './utils';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const ErrorWrapper = styled.div`
    color: black;
    margin-top: 5px;
    border: 1px solid red;
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 14px;
    height: 60px;
    display: flex;
    background-color: #f8d7da;
    align-items: center;
`;

interface InputTextProps{
    title?: string;
    teamId?: any;
    teamMembers?: Employee[];
    employees?: Employee[];
    fetchEmployees?: () => void;
}

const AddTeamMemberFeature:React.FC<InputTextProps> = ({ title, teamId, teamMembers = [], employees = [], fetchEmployees })=> {

    const employeesOptions = mapToOption(employees);
    const membersOptions = mapToOption(teamMembers);
    const filteredEmployeesOptions = arrayDifference(employeesOptions, membersOptions);
    const [selectedEmployee, setSelectedEmployee] = useState(filteredEmployeesOptions[0]);

    const [notSent, setNotSent] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const handleSubmit = async ()=> {
        if (!selectedEmployee) {
            setError("No employee has been selected");
            return;
        }

        try {
            await createTeamMembership({
                teamId,
                userId: selectedEmployee.value
            });
            setNotSent(false);
            if (fetchEmployees) {
                fetchEmployees();
            }
        } catch (e) {
            setError(e.response?.data?.message || "Unknown error");
        }
    }

    const handleEmployeeOptionChange = (option: any) => {
        setSelectedEmployee(option);
    };

    return (
        <>
            {title}
            {error ? (
                <ErrorWrapper>{error}</ErrorWrapper>
            ) : notSent ? (
                <div>
                    {filteredEmployeesOptions.length ? (
                        <>
                            <FormItem>
                                <DropdownSelectBox
                                    label="Choose Employee"
                                    options={filteredEmployeesOptions.length ? filteredEmployeesOptions : employeesOptions}
                                    selectedOption={selectedEmployee}
                                    onChange={handleEmployeeOptionChange}
                                />
                            </FormItem>
                        <FormItem>
                            <Button text='Add Employee' color='#2a7aeb' onClick={handleSubmit} />
                        </FormItem></>
                    ) : (
                        <ErrorWrapper>No employees to add</ErrorWrapper>
                    )}

                </div>
            ) : <SentComponent title="Employee has been added!" />}
        </>
    );
};

export default AddTeamMemberFeature;