import styled from "styled-components"

const InputForm = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction:column;
`
const InputLabel = styled.div`   
    font-size: 18px;
    margin-bottom: 5px;
`;

const TextDiv = styled.div `
    padding: 0;
    color: #515251;
    font-size: 14px;
`

interface InputTextProps{
    label?: string;
    text: string
 }

const LabelComponent:React.FC<InputTextProps> = ({ label, text})=> {
    
  return (<>
    <InputForm>
        {label ? <InputLabel>{label}</InputLabel> : null }           
                <TextDiv>{text}</TextDiv>           
    </InputForm>
  </>);
}

export default LabelComponent;
