import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";

const InputStyle = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #e3e1e1;
    box-sizing: border-box;
    font-size: 14px;
    color: #3b3a39;
    font-weight: 500;
    background-color: #f9f9f9;
    outline: none;
    &:focus {
        border: 1px solid #2a7aeb;
    }
    &:hover {
        cursor: pointer;
    }
`;

interface DatePickerProps {
    showLabel?: boolean;
    selectedDate?: string;
    onDateChange?: (selectedDate: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ showLabel = true, selectedDate, onDateChange }) => {
    const currentDate = new Date().toISOString().slice(0,10);
    const [date, setDate] = useState(selectedDate || currentDate);
    const dateRef = useRef(null);

    useEffect(() => {
        setDate(selectedDate || currentDate);
    }, [selectedDate, currentDate]);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = e.target.value;
        setDate(newDate);
        onDateChange && onDateChange(newDate);
    };

    useEffect(() => {
        if (onDateChange && date) {
            onDateChange(date);
        }
    }, [date, onDateChange]);

    return (
        <div>
            {showLabel && <label htmlFor="datepicker">Choose Date</label>}
            <InputStyle
                type="date"
                id="datepicker"
                value={date}
                onChange={handleDateChange}
                ref={dateRef}
            />
        </div>
    );
};

export default DatePicker;