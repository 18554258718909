import { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../../components/input/InputText.component";
import SentComponent from "../../../../components/Sent/Sent.component";
import { addEmployee } from "../../../../services/onboardings.service";
import {WorkTypeList} from "../../../../utils/data/workTypes.data";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    workflows?: any;
    positions?: any;
    teams?: any;
    fetchEmployees?: () => void;
 }

const AddEmployeeFeature:React.FC<InputTextProps> = ({ title, positions, teams, fetchEmployees })=> {
    const [notSent, setnotSent] = useState(true);
    const handleSubmit = async ()=> {
        try {
        setnotSent(false);
        await addEmployee({            
            firstName: fName,
            lastName: lName,
            email: email,            
            teamId: teamValue,
            positionsId: positionValue,
            workType: workType
         });
        fetchEmployees && fetchEmployees();
        } catch (e) {
            setnotSent(true);
        }
    }


    const [fName, setFname] = useState("");
    const [lName, setLname] = useState("");
    const [email, setEmail] = useState("");

    const teamList = teams.map((item) =>  { return {value: item.id, label: item.name}});
    const [selectedTeamsOption, setSelectedTeamsOption] = useState(teamList[0]);


    const workList = WorkTypeList.map((item) =>  { return {value: item.id, label: item.name}});
    const [setWorkType, setSelectedWorkList] = useState(workList[0]);

    const positionsList = positions.length ? positions.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Position"}];
    const [selectedPositionsOption, setSelectedPositionOption] = useState(positionsList[0]);

    const handleFnameChange = (event: any) => {
        setFname(event.target.value);
    };

    const handleLnameChange = (event: any) => {
        setLname(event.target.value);
    };


    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePositionOptionChange = (option: any) => {
        setPosition(option.value);
        setSelectedPositionOption(option);
    };

    const handleTeamsOptionChange = (option: any) => {
        setTeamValue(option.value);
        setSelectedTeamsOption(option);
    };

    const handleWorkTypeChange = (option: any) => {

        setWorkTypes(option.value);
        setSelectedWorkList(option);
    };

    const [positionValue, setPosition] = useState(positionsList[0].value);
    const [teamValue, setTeamValue] = useState(teamList[0].value);
    const [workType, setWorkTypes] = useState(workList[0].value);
  return <>
    {title}
    {notSent ? <div><FormItem>
        <InputTextComponent label="First name" onChildStateChange = {handleFnameChange } fullWidth={true} type="text"  placeholder="First name..." />
    </FormItem>
    <FormItem>
        <InputTextComponent label="Last name" onChildStateChange = {handleLnameChange } fullWidth={true} type="text"  placeholder="Last name..." />
    </FormItem>
    <FormItem>
        <InputTextComponent label="Employee email" onChildStateChange = {handleEmailChange} fullWidth={true} type="text"  placeholder="User email..." />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Choose position" options={positionsList} selectedOption={selectedPositionsOption} onChange={handlePositionOptionChange} />
    </FormItem>
    <FormItem>
    <DropdownSelectBox  label="Choose Team" options={teamList} selectedOption={selectedTeamsOption} onChange={handleTeamsOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox  label="Work Type" options={workList} selectedOption={setWorkType} onChange={handleWorkTypeChange} />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() } >Add Employee</Button>
    </FormItem></div> : <SentComponent title="Employee invitation has been sent!" /> }
  </>
};

export default AddEmployeeFeature;
