import * as React from 'react';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../../layouts/DashboardLayout';
import InputTextComponent from "../../../components/input/InputText.component";
import { updatePosition, getPosition } from "../../../services/positions.service";
import LinkButton from '../../../components/LinkButton/LinkButton.component';
import Button from '../../../components/Buttons/Button.component';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:10px;
    flex-wrap: nowrap;
    margin-top: 15px;
`;

function EditPositionsPage() {
     
    const [position, setPosition] =  useState<any>();
    const [name, setName] =  useState('');
    const [description, setDescription] = useState('');    
    const navigate = useNavigate();   

    let { id  } = useParams();
    React.useEffect(() => {      
        async function getPositionDetails() {            
            var position = await getPosition(id);         
            setPosition(position);
            setName(position.name);   
            setDescription(position.description);      
        }
        getPositionDetails();        
    },[id]);    

    const handleNameChange = (event: any) => {
        setName(event.target.value);        
    };

    const handleDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async ()=> {             
        try{
            await updatePosition(id, { name: name, description: description });
            navigate('/settings/positions');
        }
        catch(err){          
            console.log(err);
        }
    }    

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Action edit page</div>                
            </div>
            
    {(position != null)?
    <Form>    
    <FormItem>
        <InputTextComponent type='text' label="Name" placeholder="type a name" fullWidth={true} value={name} onChildStateChange = {handleNameChange}  />
    </FormItem>  
    <FormItem>
        <InputTextComponent type='text' label='Description' fullWidth={true} placeholder="type a description" onChildStateChange = {handleDescriptionChange}   value={description} />
    </FormItem>
    <FormButtonsItem>
        <Button  text='Save'  color='#2a7aeb' onClick={ () => handleSubmit() } />         
        <LinkButton onClick={ () => navigate('/settings/positions') } text='Cancel'></LinkButton>              
    </FormButtonsItem>      
    </Form> : null }
    </DashboardLayout>
  </>
};

export default EditPositionsPage;