import * as React from 'react';
import BoardingTabFeature from '../../features/boarding/tabs/BoardingTab.feature';
import DashboardLayout from '../../layouts/DashboardLayout';
import './styles/boarding.style.css';
interface Props {
    children?: React.ReactNode
}
const BoardingPage: React.FunctionComponent<Props> = (props:Props) => {
    return <>
        <DashboardLayout>
             <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Boarding </div>
            </div>
            <BoardingTabFeature title="Boarding tab" />
        </DashboardLayout>
    </>
}

export default BoardingPage;