import { apiClient } from "../infrastructure/api/apiClient";

export const getAssetsCatalog = () => {
    return apiClient.get('/assets')
        .then((response)=> {
            return response.data;
        });
};

export const getAssetById = (assetId: string) => {
    return apiClient.get(`/assets/${assetId}`)
        .then((response)=> {
            return response.data;
        });
};

export const createAsset = (payload: any) => {
    return apiClient.post('/assets/create-asset', payload)
        .then((response)=> {
            return response.data;
        });
};

export const assignAssetToEmployee = (payload: any) => {
    return apiClient.post('/assets/issue', payload)
        .then((response)=> {
            return response.data;
        });
};