import * as React from 'react';
import EmployeeTabFeature from '../../features/boarding/tabs/EmployeeTab.feature';
import DashboardLayout from '../../layouts/DashboardLayout';
import './styles/employees.style.css';
interface Props {
    children?: React.ReactNode
}
const EmployeesPage: React.FunctionComponent<Props> = (props:Props) => {
    return <>
        <DashboardLayout>
             <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Employees </div>
            </div>
            <EmployeeTabFeature title="Employee tab" />
        </DashboardLayout>
    </>
}

export default EmployeesPage;