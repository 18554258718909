import React, { useState } from "react";
import { uploadFile } from "../../services/actions.service";
import styled from "styled-components";
import AttachedFiles from '../fileUploader/attachedFile.components';
import UploadIcon from '../../assets/svg/upload.svg'

const DropContainer = styled.label`
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #ebebeb;
  color: #444;
  cursor: pointer;
`;

const DropTitle = styled.span`
  color: #444;
  font-size: 16px;
  text-align: center;
  transition: color 0.2s ease-in-out;
`;

const Title = styled.div`
  font-size: 14px;
  color: #515251;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const FileInput = styled.input`
  display: none;
`;

const Button = styled.label`
  border-radius: 15px;
  border: 1px solid lightgray;
  font-size: 0.775rem;
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
  line-height: 1.125rem;
  cursor: pointer;  
`;

const LoaderDiv = styled.div`
  display:flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;

const Loader = styled.img`
  width: 50px;
  height: 50px;
`;

interface Props {
  label: string;
  callback: any;
}

const FileUploader: React.FC<Props> = ({ label, callback }) => {
  const [startProcessing, setStartProcessing] = useState(false); 
  const [uploadFiles, setuploadFiles] = useState(null);

  const startFileProcessing = () => {
    setStartProcessing(true);   
  };

  const finishFileProcessing = () => {
    setStartProcessing(false);   
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    startFileProcessing();
    const response = await uploadFile(files);
    setuploadFiles(response);
    finishFileProcessing();
    callback(response);    
  };

  return (
    <>
      <Title>{label}</Title>
      { (uploadFiles)? 
        <AttachedFiles files={uploadFiles} title="Attachments" />
       :
      <DropContainer htmlFor="file-upload">               
        { (startProcessing)? 
           <LoaderDiv>
           <Loader src={UploadIcon} alt="uploading" />
           <DropTitle>uploading...</DropTitle> 
           </LoaderDiv>
          : null }       
        { (!startProcessing)? <><DropTitle>Choose files to upload</DropTitle> <Button htmlFor="file-upload">Browse</Button></> : null }        
        <FileInput
          type="file"
          id="file-upload"
          accept="application/pdf"
          onChange={handleFileChange}
          multiple
          required
        />
      </DropContainer>
      }
    </>
  );
};

export default FileUploader;
