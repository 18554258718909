import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authHeader from "../services/authHeader.service";

const useAuthorization = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_ENDPOINT;
  const redirectTo = "/";
  const authorized = true;
  useEffect(() => {
      
    const fetchAuthorization = async () => {
      try {
        const response = await fetch(api_url + "/auth/me", 
            { headers: authHeader() 
        });
        if (response.ok) {
          setIsAuthorized(true);
        } else {
          navigate(redirectTo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (authorized) {
      fetchAuthorization();
    } else {
        navigate(redirectTo);
    }
  }, [authorized, navigate, redirectTo, api_url]);

  return isAuthorized;
};

export default useAuthorization;
