import { apiClient } from "../infrastructure/api/apiClient";

export const getPositions = () => {
  return apiClient.get('/settings/positions')
  .then((response)=> {    
    return response.data;
  });
};

export const getPosition = (id:string) => {
  return apiClient.get(`/settings/positions/details/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const createPositions = (payload: any) => {
    return apiClient.post('/settings/positions', payload);
};

export const updatePosition = (id:string, payload: any) => {
  return apiClient.put(`/settings/positions/${id}`, payload)
  .then((response)=> {    
    return response.data;
  });
};

export const deletePosition = (id:string) => {
  return apiClient.delete(`/settings/positions/${id}`)
  .then((response)=> {    
    return response.data;
  });;
};