import React from 'react';
import styled from 'styled-components';

const TagContainer = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin: 3px;
  background-color: #f2f2f2;
  border-radius: 25px;
  color: #454545;
  font-size: 13px;
  font-weight: lighter;
  line-height: 1;
`;

interface Props {
    title: string;
}

const Tag: React.FunctionComponent<Props> = ({ title }) => {
    return <TagContainer>{title}</TagContainer>;
};

export default Tag;