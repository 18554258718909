import * as React from 'react';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../../layouts/DashboardLayout';
import DropdownSelectBox from "../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../components/input/InputText.component";
import { updateAction } from "../../../services/actions.service";
import { getUsers } from "../../../services/user.service";
import { getActionDetails } from '../../../services/actions.service';
import LinkButton from '../../../components/LinkButton/LinkButton.component';
import Button from '../../../components/Buttons/Button.component';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:10px;
    flex-wrap: nowrap;
    margin-top: 15px;
`;

function EditActiondPage() {         
    
    const reversibleTypes = [{value: "REVERSIBLE", label: "Reversible"}, {value: "NOT_REVERSIBLE", label: "Not Reversible"}];
    const [actionName, setActionName] =  useState<any>();
    const [description,setDescription] = useState('');
    
    const [action, setAction] = useState<any>(() => null);
    const [assigneeUser, setAssigneeUser] =  useState<any>();    
    const [userOptions, setUserOption] = useState<any[]>(() => []);
    const [reversibleType, setReversibleType] = useState<any>();
    
    const navigate = useNavigate();
   

    let { id  } = useParams();
    React.useEffect(() => {      
        async function getAction() {            
            var action = await getActionDetails(id);                
            setAction(action); 
            setActionName(action.name);   
            setDescription(action.description);    
            setAssigneeUser(action.assignee.id);            
            setReversibleType(action.reversibleType);
        }
        getAction();
        
    },[id]);

    React.useEffect(() => {
        async function getUserList() {          
            var usersResponse = await getUsers();
            const userOptions = usersResponse.users.map((item)=> { return {value: item.id, label: `${item.firstName} ${item.lastName}`  }} );     
            setUserOption(userOptions);          
        }        
        getUserList();
    },[]);


    const handleUserOptionChange = (option: any) => {       
        setAssigneeUser(option.value);
    };   

    const handleReversibleTypeChange = (option: any) => {        
        setReversibleType(option.value);
    };
    
    const handleActionNameChange = (event: any) => {
        setActionName(event.target.value);        
    };

    const handleDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async ()=> {      
        try{
            await updateAction(id, { name: actionName, description: description, assigneeId: assigneeUser, reversible: reversibleType });
            navigate('/workflow');
        }
        catch(err){          
            console.log(err);
        }
    }    

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Action edit page</div>                
            </div>
            
    {(action != null && userOptions.length > 0)?
    <Form>    
    <FormItem>
        <InputTextComponent type='text' label="Name" placeholder="type a name" fullWidth={true} value={actionName} onChildStateChange = {handleActionNameChange}  />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Assign to"  options={userOptions} selectedOption={userOptions.find((item) => item.value === assigneeUser)} onChange={handleUserOptionChange} />
    </FormItem>   
    <FormItem>
        <DropdownSelectBox label="Action type"  options={reversibleTypes}  selectedOption={reversibleTypes.find((item) => item.value === reversibleType)} onChange={handleReversibleTypeChange} />
    </FormItem>    
    <FormItem>
        <InputTextComponent type='text' label='Description' fullWidth={true} placeholder="type a description" onChildStateChange = {handleDescriptionChange}   value={description} />
    </FormItem>
    <FormButtonsItem>
        <Button  text='Save'  color='#2a7aeb' onClick={ () => handleSubmit() } />         
        <LinkButton onClick={ () => navigate('/workflow') } text='Cancel'></LinkButton>           
    </FormButtonsItem>     
    </Form> : null }
    </DashboardLayout>
  </>
};

export default EditActiondPage;