import * as React from 'react';
import { getOffboardings } from '../../../services/onboardings.service';
import { convertDate } from '../../../utils/date';
import {useNavigate} from "react-router-dom";
import { EmptyBox } from '@rocket-desert/shared-ui';
import SpinnerWrapper from "../../../components/Spinner/SpinnerWrapper.component";
import {useState} from "react";
import OffboardingTableItem  from './offboarding-table-items.feature'
import OffboardingTable from './offboarding-table.feature';
import styled from "styled-components";

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
}

const EmptyOffboarding = styled.div`
    margin-top: 20px;
`;


export const offboardingUsersList: any[] = [];

const OffboardingFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls }) => {
    const [offboardings, setOffboardings] = React.useState<any>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    React.useEffect(()=> {
        async function getOffboardingsList() {
            setIsLoading(true);
            const offboardings = await getOffboardings();
            setOffboardings(offboardings);
            setIsLoading(false);
        }
        getOffboardingsList();
    },[]);
    return <>
        { isLoading ? <SpinnerWrapper /> : <>
        { offboardings.length ?
        <OffboardingTable
            title = { title }
            showControls = { showControls }
            showTitle = { showTitle }
            showSearch = { showSearch }
            isOnboarding={false}
        >
           {offboardings.length ? offboardings.map((item, i)=> {
                return <OffboardingTableItem
                    id={i + 1}
                    key={item.id}                    
                    percentage={item.percentage}
                    position={item.position.name}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    status={item.status}
                    startDate={convertDate(item.startDate)}
                    team={item.team ? item.team.name : null}
                    onClick={()=> handleNavigate(`/offboarding/${item.id}`)}
                    createdAt={ convertDate(item.createdAt)}
                ></OffboardingTableItem>
            }) : null }
        </OffboardingTable> : <EmptyOffboarding><EmptyBox message="There is no any offboardings" /></EmptyOffboarding> } </> }
    </>
}

export default OffboardingFeature;
