import { useState } from "react";
import styled from "styled-components";
import LinkButton from "../../components/LinkButton/LinkButton.component";

const InputForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;
const AnswersForm = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const InputLabel = styled.div`
  font-size: 18px;
  margin-bottom: 5px;
`;

const TextDiv = styled.div`
  padding: 5px;
  color: #515251;
  font-size: 14px;
`;

const ActionsForm = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  gap: 15px;
  margin-top: 15px;
`;

const QuestionInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-size: 18px;
  height: 50px;
  padding: 7px 5px;
  width: 100%;
  outline: none;
  background-color: #e7e9eb;
`;

const AnswerInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-size: 14px;
  padding: 7px 5px;
  width: 50%;
  outline: none;
  background-color: #e7e9eb;
`;

interface Props {
  index: number;
  assessment: any;
  onEdit?: (assessment: any, index: number) => void;
  onRemove?: (assessment: any, index: number) => void;
}

const AssessmentsItem: React.FC<Props> = ({
  index,
  assessment,
  onEdit,
  onRemove,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [savedQuestion, setSavedQuestion] = useState(assessment.question);
  const [tempQuestionValue, setTempQuestionValue] = useState("");
  const [savedAnswers, setSavedAnswers] = useState(
    assessment.answers.map((answer) => answer)
  );
  const [tempEditValues, setTempEditValues] = useState(["", "", ""]);
  const [tempCorrectAnswers, setTempCorrectAnswers] = useState<string[]>();
  const [savedCorrectAnswers, setSavedCorrectAnswers] = useState<string[]>(
    assessment.correctAnswer
  );

  const handleEdit = () => {
    setTempEditValues(savedAnswers);
    setTempQuestionValue(savedQuestion);
    setTempCorrectAnswers(savedCorrectAnswers);
    setIsEditMode(true);
  };

  const handleAnswerInputChange = (index, value) => {
    const updatedValues = [...tempEditValues];
    updatedValues[index] = value;
    setTempEditValues(updatedValues);
  };

  const handleCorrectAnswerInputChange = (value) => {
    setTempCorrectAnswers([value]);
  };

  const handleQuestionInputChange = (value) => {
    setTempQuestionValue(value);
  };

  const handleSave = () => {
    setSavedAnswers(tempEditValues);
    setSavedQuestion(tempQuestionValue);
    setSavedCorrectAnswers(tempCorrectAnswers);
    setIsEditMode(false);
    if (onEdit)
      onEdit(
        {
          question: tempQuestionValue,
          answers: tempEditValues,
          correctAnswer: tempCorrectAnswers,
        },
        index
      );
  };

  const handleRemove = () => {
    if (onRemove)
      onRemove(
        {
          question: savedQuestion,
          answers: savedAnswers,
          correctAnswer: savedCorrectAnswers,
        },
        index
      );
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  return (
    <>
      {!isEditMode ? (
        <InputForm>
          <InputLabel>{savedQuestion}</InputLabel>
          {savedAnswers.map((answer, index) => (
            <TextDiv key={index + 1}>
              {index + 1}. {answer}{" "}
              {savedCorrectAnswers.includes(answer) ? (
                <span>
                  <b>(correct answer)</b>
                </span>
              ) : null}{" "}
            </TextDiv>
          ))}
          <ActionsForm>
            <LinkButton text="Edit" onClick={handleEdit} />
            <LinkButton text="Remove" onClick={handleRemove} />
          </ActionsForm>
        </InputForm>
      ) : (
        <InputForm>
          <InputLabel>
            <QuestionInput
              type="text"
              value={tempQuestionValue}
              onChange={(e) => handleQuestionInputChange(e.target.value)}
            />
          </InputLabel>
          {tempEditValues.map((answer, index) => (
            <AnswersForm>
              <input
                key={`c_${index}`}
                type="radio"
                name="correct"
                value={answer}
                onChange={(e) => handleCorrectAnswerInputChange(e.target.value)}
                checked={tempCorrectAnswers.includes(answer) ? true : false}
              />
              <AnswerInput
                key={`a_${index}`}
                type="text"
                value={answer}
                onChange={(e) => handleAnswerInputChange(index, e.target.value)}
              />
            </AnswersForm>
          ))}

          <ActionsForm>
            <LinkButton text="Save" onClick={handleSave} />
            <LinkButton text="Cancel" onClick={handleCancel} />
          </ActionsForm>
        </InputForm>
      )}
    </>
  );
};

export default AssessmentsItem;
