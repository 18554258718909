export enum Status {
    STARETD='STARTED',
    MIDDLE='MIDDLE',
    SOON='SOON',
    ALMOST='ALMOST',
    COMPLETED='COMPLETED'
}
export const CalculatePercentageStatus = (percentage: number): Status  => {
    switch(true) {
        case (percentage > 0 && percentage <= 50):
            return Status.STARETD;
        case (percentage > 50 && percentage <= 75):
            return Status.MIDDLE;
        case (percentage > 75 && percentage <= 90):
            return Status.SOON;
        case (percentage > 90 && percentage <= 99):
            return Status.ALMOST;
        case (percentage === 100):
            return Status.COMPLETED;  
        default:
            return Status.STARETD;                               
    }
};

export const CalculateStatusColor = (status: Status): string => {
    switch(status) {
        case (Status.STARETD):
            return '#e0e0e0';
        case (Status.MIDDLE):
            return '#d8de35';
        case (Status.SOON):
            return '#358cde';
        case (Status.ALMOST):
            return '#6ee660';
        case (Status.COMPLETED):
            return '#36ab29';                    
        default:
            return '#e67760';                               
    }
}