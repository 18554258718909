import styled from "styled-components"

const ColoredBox = styled.div<ColorProps>`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    background-color: ${props => props.color};
    margin-right: 10px;
    color: white;
    font-size: 14px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
`;

const BoxWrapper = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 400;
`;

interface ColorProps {
    color: string;
}

interface InputTextProps{
    symbol: string;    
    color: string;
    onClick?: React.MouseEventHandler;
 }

const SymbolBoxComponent:React.FC<InputTextProps> = ({ symbol, color, onClick })=> (
  <>
    <BoxWrapper onClick={onClick}>
        <ColoredBox color={color}>{symbol}</ColoredBox>        
    </BoxWrapper>
  </>
)

export default SymbolBoxComponent;
