import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import ModalComponent from '../../components/modal/modal.component';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getTeamLeaders } from "../../services/user.service";
import { convertDate } from "../../utils/date";
import './styles/departments.style.css';
import styled from "styled-components";
import SpinnerWrapper from "../../components/Spinner/SpinnerWrapper.component";
import NewDepartmentsFeature from "../../features/departments/create-department.feature";
import {getDepartments} from "../../services/departments.service";
import { EmptyBox } from "@rocket-desert/shared-ui";

const StyledTh = styled.th`
    padding-left: 10px;
`;

const StyledH1 = styled.h1`
    padding-left: 0;
    margin-left: 0;
`;

const StyledTd = styled.td`
    padding-left: 10px;
`;

const DepartmentsPage: React.FunctionComponent = () => {
    const {isShowing, toggle} = useModal();
    const [leaders, setLeaders] = useState<{id: number | string; firstName: string}[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    useEffect((): void=> {
        (async function getLeaders(): Promise<void> {
            const users = await getTeamLeaders();
            setLeaders(users.users);
        })();
    },[]);

    const fetchDepartments = async () => {
        setIsLoading(true);
        const departments = await getDepartments();
        setDepartments(departments);
        setIsLoading(false);
    }

    useEffect(()=> {
        async function getChapterList() {
            setIsLoading(true);
            const departments = await getDepartments();
            setDepartments(departments);
            setIsLoading(false);
        }
        getChapterList();
    }, []);



    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Departments Page </div>
            </div>

            <div className="controls-page">
                <div>
                    <StyledH1>All Departments</StyledH1>
                </div>
                <div className="controls-list-page">
                    <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="+ New Department" onClick={ toggle } />
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Department"
                >
                  {leaders.length?  <NewDepartmentsFeature fetchDepartments={fetchDepartments} users={leaders} title = 'Create Chapter'/> : <EmptyBox message={'There is no any employee find who will  be assign as department lead'}></EmptyBox> }
                </ModalComponent>

            </div>
            { isLoading ? <SpinnerWrapper /> :
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>                   
                    <StyledTh>Name</StyledTh>
                    <th>Status</th>
                    <th>Department Head</th>
                    <th>Created at</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                { departments ? departments.map((item)=> {
                    return <tr className="team-tr" key={item.id} onClick = { ()=> handleNavigate(`${item.id}/view`)}>                    
                    <StyledTd>{item.name}</StyledTd>
                    <td>{item.status}</td>
                    <td>{item.chapterLead.firstName} {item.chapterLead.lastName}</td>                    
                    <td>{ convertDate(item.createdAt)}</td>
                    <td><span onClick={(e) => { e.stopPropagation(); handleNavigate(`/departments/edit/${item.id}`);}}>Edit</span></td>
                </tr>
                }) : null }
                </tbody>
            </table> }
        </DashboardLayout>
    </>
}

export default DepartmentsPage;