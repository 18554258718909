import * as React from 'react';
import styled from "styled-components";
import SearchBoxComponent from '../../../components/SearchBox/SearchBox.component';

const TableTitle = styled.div`
    font-weight: 600;
    margin-top: 30px;
    color: #474443;
`;

const Table = styled.table`
    width: 100%;
    padding: 10px;
    margin: 0;
    border-collapse: collapse;
    margin-top: 10px;
`;

const Thead = styled.thead`
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 10px;
    height: 40px;
    color: #121111;
    border: 1px solid #e0e0e0;
    font-size: 13px;
`;

const ThItem = styled.th`
    text-align: center;
    min-width: 10px;
`;

const Fullname = styled.th`
    width: 220px;
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
`;

const Percentage = styled.th`
    width: 130px;
`;

export enum TabsEnum {
    ONBOARDING,
    OFFBOARDING,
    EMPLOYED
}

interface Props {
    title?: string;
    children?: React.ReactNode | null;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
    fetchOnboardings?: () => void;
    isOnboarding?: boolean;
}
const OnboardingTable: React.FunctionComponent<Props> = ({ title, children, showTitle, showSearch, isOnboarding = true}) => {

    return <>
        <HeadingWrapper>
            {showTitle ? <div><TableTitle>{ title }</TableTitle></div> : null }
            {showSearch ? <SearchBoxComponent placeholder="Search by name..." /> : null }
        </HeadingWrapper>
        <Table cellPadding="0" cellSpacing="0">
            <Thead>
                <tr>
                    <ThItem></ThItem>
                    <Fullname>Employee</Fullname>
                    <th>Status</th>
                    <th>Team</th>
                    <th>Workflow</th>
                    <Percentage>Progress</Percentage>
                    <th>{isOnboarding ? 'Starts on' : 'From' }</th>
                    <th>Days</th>
                    <th>Created at</th>
                </tr>
            </Thead>
            <tbody>
                 { children }
            </tbody>
        </Table>
    </>
}

export default OnboardingTable;
