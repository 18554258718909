import {  useState } from 'react';
import './styles/resetPassword.style.css';
import { useNavigate } from 'react-router-dom';
import InputTextComponent from '../../components/input/InputText.component';
import { resetPassword } from '../../services/auth.service';
import { InfoMessageComponent, MessageTypes } from '../../components/InfoMessage/InfoMessage.component';
import IndexLayoutPage from '../../layouts/IndexLayout';

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');  
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const handleSubmit = async () => await resetPassword(email,'dashboard')
        .then(() => showSuccessMessage())        
        .catch(() =>  showErrorMessage());    

  const showSuccessMessage = () => {  
    setError(false);
    setSuccess(true);
  };

  const showErrorMessage = () => {  
    setError(true);
    setSuccess(false);
  };

  const resetMessages = () => {  
    setError(false);
    setSuccess(false);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    resetMessages();
  };

  return (
   
    <IndexLayoutPage>
        <div className="Texthead">
                <h3>Reset your password</h3>
                <span></span>
              </div>
              <div className="responsive-head">
                <h3>Reset your password</h3>
                <span></span>
              </div>
              <div className="form">                

                  <InputTextComponent onChildStateChange={handleEmailChange}  label="Enter your email" type="email" /> 

                 {error ? <InfoMessageComponent text='Password reset failed. Please enter valid email' type={MessageTypes.Error} /> : null}
                 
                 {success ? <InfoMessageComponent text='We have e-mailed your password reset link, Please check your email!' type={MessageTypes.Success} /> : null}

                  <div className="loginButton">
                      <div className="loginBtn" onClick={handleSubmit}>Send password reset link</div>
                  </div>

                  <div className="getAccess">
                      <div>Already have an account?</div>&nbsp;<div className='link' onClick={ () => navigate('/') }> Log in </div>&nbsp;<div>to your account</div>
                  </div>
              </div>
    </IndexLayoutPage>  
   
  );
}

export default ResetPasswordPage;
