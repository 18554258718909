import { apiClient } from "../infrastructure/api/apiClient";

export const getTeams = () => {
  return apiClient.get('/team')
  .then((response)=> {    
    return response.data;
  });
};

export const createTeam = (payload: any) => {
    return apiClient.post('/team/create-team', {name: payload.name, teamLeadId: payload.teamLeadId});
};

export const getTeamById = (id: string) => {
  return apiClient.get(`/team/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const removeEmployeeFromTeam = (teamId: string, employeeId: string) => {
    return apiClient.delete(`/team/${teamId}/${employeeId}`);
};

export const updateTeam = (id:string, payload: any) => {
  return apiClient.put(`/team/${id}`, payload)
  .then((response)=> {    
    return response.data;
  });
};

export const createTeamMembership = (payload: any) => {
  return apiClient.post('/team/create-team-membership', payload);
};
