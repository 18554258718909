import * as React from 'react';
import styled from "styled-components";
import { convertDate } from '../../../utils/date';
import {ActionType, getReadableActionType} from "../../../utils/action-types.utils";
import {toTitleCase} from "../../../utils/title-case.util";

const Row = styled.tr`
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
`;

const NameColumn = styled.td`
    text-align: left;
    padding-left : 5px;
`;

const Actions = styled.div`
    display:flex;
    flex-direction: row;
    gap: 5px;
`;

export const StatusContainer = styled.div<{status: string}>`
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ status }) => {
    switch (status) {
        case 'ACTIVE':
            return '#b2bec3';
        case 'INACTIVE':
        default:
            return '#00b894';
    }
}};
`;

interface Props {
    id: string | number;
    name: string;
    status: string;
    owners: string;
    revertible: string;
    actionType: string;
    createdAt: string;
    onView?: React.MouseEventHandler;
    onEdit?: React.MouseEventHandler;
    onDelete?: React.MouseEventHandler;
}
const ActionsTableItem: React.FunctionComponent<Props> = ({ id, name, actionType, revertible, status, owners, createdAt, onView, onEdit,  onDelete}) => {
    return <Row key={id}>
        <NameColumn> {name} </NameColumn>
        <td>
            <StatusContainer status={status}>
                {toTitleCase(status)}
            </StatusContainer>
        </td>
        <td> {owners} </td>
        <td> {revertible} </td>
        <td> {getReadableActionType(actionType as ActionType)} </td>
        <td>{convertDate(createdAt)}</td>
        <td>
            <Actions>
            <span onClick={onView}>View</span> 
            <span onClick={onEdit}>Edit</span> 
            <span onClick={onDelete}>Delete</span>
            </Actions>
        </td> 
    </Row>
}

export default ActionsTableItem;