import { apiClient } from "../infrastructure/api/apiClient";

export const getDepartments = () => {
  return apiClient.get('/chapter')
  .then((response)=> {    
    return response.data;
  });
};

export const createDepartment = (payload: any) => {
    return apiClient.post('/chapter', payload)
    .then((response)=> {    
      return response.data;
    });
};

export const getDepartment = (id: string) => {
  return apiClient.get(`/chapter/details/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const updateDepartment = (id:string, payload: any) => {
  return apiClient.put(`/chapter/${id}`, payload)
  .then((response)=> {    
    return response.data;
  });
};