import { apiClient } from "../infrastructure/api/apiClient";

export const getPublicContent = () => {
  return apiClient.get('/all');
};

export const getUserBoard = () => {
  return apiClient.get('/user');
};

export const getEmployeeById = (userId: string) => {
  return apiClient.get(`/user/members/${userId}`);
};

export const getUsers = (searchKeyword?: string) => {
  const keyword = searchKeyword ? searchKeyword : "";
  return apiClient.get(`/user/members?searchKeyword=${keyword}`)
  .then((response)=> {
    return response.data;
  });
};

export const searchUser = (searchKeyword?: string) => {
  const keyword = searchKeyword ? searchKeyword : "";
  return apiClient.get(`/user/search?query=${keyword}`);
};


export const getTeamLeaders =  () => {
  return  apiClient.get('/user/team-leaders')
  .then((response)=> {
    return response.data;
  });
}

export const getModeratorBoard = () => {
  return apiClient.get('/mod');
};

export const getAdminBoard = () => {
  return apiClient.get('/admin');
};

export const removeEmployee = (employeeId: string) => {
  return apiClient.put(`/user/${employeeId}/delete`, {});
};

export const searchEmployee = (searchKeyword: string) => {
  return apiClient.put(`/user/search?query=${searchKeyword}`);
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  return await apiClient.post("/user/change-password", {
    currentPassword,
    newPassword
  }).then((response) => {
    return response;
  });
};


