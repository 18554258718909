import styled from "styled-components";
import PdfIcon from '../../assets/svg/pdf-icon.svg'

const Title = styled.div`
  font-size: 14px;
  color: #515251;
  margin-top: 15px;
  margin-bottom: 5px;
`;

interface Files{
    fileName: string,
    filePath: string,
    presignedUrl: string
}

interface Props {
  title?: string;
  files: Files[];  
}

const FileContainer = styled.div`
  margin-top: 10px;
  text-align: left;
  width: 100%;
  display:flex;
  flex-direction: row;
  gap: 10px;
`;

const Img = styled.img`
  height:25px;
  width: 25px;
`
const Link = styled.a`
  color: #515251;
  outline-color: transparent;
  text-decoration: none;
  padding: 2px 1px 0;  
`

const AttachedFiles: React.FC<Props> = ({ title, files }) => { 

  return (
    (!files?.length) ?
    <span>There is no any attachments</span>
    : <>
      {(title)? <Title>{title}</Title> : null}
      { files.map((item, index) => { return <FileContainer key={index} ><Img src={PdfIcon} alt="Logo"  /><Link href={item.presignedUrl} target="_blank" rel="noreferrer">{item.fileName}</Link></FileContainer>})  }     
    </>
     
  );
};

export default AttachedFiles;
