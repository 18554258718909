import styled from "styled-components"

const InputForm = styled.div`
    margin-top: 20px;
`
const InputLabel = styled.div`
    color: #515251;
`;

const InputWrapper = styled.div``;

const InputText = styled.textarea<InputTextProps>`
    width: ${props => props.fullWidth ? '100%' : '400px'};
    height: 50px;
    font-size: 14px;
    resize: none;
    border: 1px solid #ebebeb;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

interface InputTextProps{
    label?: string;
    value?:string;
    placeholder?: string;
    fullWidth?: boolean;
    onChildStateChange?: any;
 }

const TextBoxComponent:React.FC<InputTextProps> = ({ label, value, placeholder, fullWidth, onChildStateChange })=> (
  <>
    <InputForm>
        {label ? <InputLabel>{label}</InputLabel> : null }
            <InputWrapper>
                <InputText label={label} fullWidth={fullWidth} onChange={onChildStateChange} placeholder={placeholder} value={value} />
            </InputWrapper>
    </InputForm>
  </>
)

export default TextBoxComponent;
