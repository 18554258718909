import * as React from 'react';
import DashboardLayout from '../../../layouts/DashboardLayout';
import './styles/boarding.details.css';
import {useEffect, useState} from "react";
import {getOnboardingById} from "../../../services/onboardings.service";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {convertDate} from "../../../utils/date";
import {getWorkflowItems, removeAssignedTask} from "../../../services/tasks.service";
import useModal from "../../../hooks/useModal";
import ModalComponent from "../../../components/modal/modal.component";
import AssignTaskFeature from "../../../features/task/assign.feature";
import {getActions} from "../../../services/actions.service";
import {EmptyBox} from "@rocket-desert/shared-ui";
import SpinnerWrapper from "../../../components/Spinner/SpinnerWrapper.component";
import CreateButtonComponent from "../../../components/Buttons/CreateButton.component";
import {toTitleCase} from "../../../utils/title-case.util";
interface Props {
    children?: React.ReactNode
}

const BoardingContainer = styled.div`
  border: 1px solid #f7f7f7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;


const BasicInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const BoxItemPosition = styled.div`
  min-width: 180px;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0;
`;

const BoxItemTeam = styled.div`
  min-width: 140px;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0;
`;

const BoxItemStatus = styled.div`
  min-width: 100px;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0; 
`;

const BoxItemProgress = styled.div`
  min-width: 100px;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0;    
`;

const BoxItemStartDate = styled.div`
  min-width: 100px;
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0;  
`;

const BoxTitle = styled.div`
  font-weight: bold;
`;
const BoxContent = styled.div`
  padding-top: 15px;
`;

const WorkflowBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const Title = styled.div`
  display: flex;  
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const WorkflowTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top:20px;
`;

const WorkflowRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
`;


const WorkflowHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  padding: 15px;
`;

const TaskTitle = styled.div`
  width: 200px;
`;
const TaskStatus = styled.div`
  width: 100px;
`;
const TaskStartDate = styled.div`
  width: 200px;
`;
const TaskDueDate = styled.div`
  width: 200px;
`;
const TaskAssignee = styled.div`
  width: 200px;
`;

const TaskTitleHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskStatusHead = styled.div`
  width: 100px;
  font-weight: bold;
`;
const TaskStartDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskDueDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskAssigneeHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskActionHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
margin-bottom: 10px;
`;

const TaskAction = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveActive = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveDisabled = styled.div`
  width: 200px;
  color: gray;
`;


const StatusContainer = styled.div<{status: string}>`
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ status }) => {
    switch (status) {
        case 'TODO':
            return '#b2bec3';
        case 'IN PROGRESS':
            return '#fdcb6e';
        case 'COMPLETED':
            return 'green';
        default:
            return '#00b894';
    }
}};
`;

const OnboardingDetailsPage: React.FunctionComponent<Props> = () => {
    const {isShowing, toggle} = useModal();
    const [onboarding, setOnboarding] = useState<any>();
    const [workflowTasks, setWorkflowTasks] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    let { boardingId } = useParams();
    async function removeAssigned(taskId: string) {
        await removeAssignedTask(taskId);
       fetchBoardings();
    }

    useEffect(()=> {
        async function getOnboarding() {
            const onboarding = await getOnboardingById(boardingId);
            setOnboarding(onboarding.data)
        }
        getOnboarding();
    },[boardingId]);

    const [actions, setActions] = React.useState<any[]>([]);

    React.useEffect(() => {
        setIsLoading(true);
        getActions().then(rawActions => {
            const filteredActions = rawActions.filter(action =>
                !workflowTasks.some(task => task.actionId === action.id)
            );
            setActions(filteredActions);
            setIsLoading(false);
        });
    }, [workflowTasks]); // Run this effect when workflowTasks changes

    const fetchBoardings = async () => {
        const workflowTasks = await getWorkflowItems(boardingId);
        setWorkflowTasks(workflowTasks.data)
    }

    const modalToggle = async () => {
        const result = await getWorkflowItems(boardingId);
        setWorkflowTasks(result.data);
        toggle();
    }

    useEffect(()=> {
        async function getWorkflowTasks() {
            const workflowTasks = await getWorkflowItems(boardingId);
            setWorkflowTasks(workflowTasks.data)
        }
        getWorkflowTasks();
    },[boardingId]);

    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Onboarding</div>
            </div>
            { isLoading ? <SpinnerWrapper /> : <><div className="controls-page">               
            </div>
            {
             onboarding ?
            <BoardingContainer>
                <Title>Employee Details</Title>
                <BasicInfo>                    
                    <BoxItemPosition>
                        <BoxTitle>Name</BoxTitle>
                        <BoxContent>{onboarding.firstName} {onboarding.lastName}</BoxContent>
                    </BoxItemPosition>
                    <BoxItemPosition>
                        <BoxTitle>Position</BoxTitle>
                        <BoxContent>{onboarding.position}</BoxContent>
                    </BoxItemPosition>
                    <BoxItemTeam>
                        <BoxTitle>Team</BoxTitle>
                        <BoxContent>{onboarding.team}</BoxContent>
                    </BoxItemTeam>
                    <BoxItemStatus>
                        <BoxTitle>Status</BoxTitle>
                        <BoxContent>{onboarding.status}</BoxContent>
                    </BoxItemStatus>
                    <BoxItemProgress>
                        <BoxTitle>Progress</BoxTitle>
                        <BoxContent>{onboarding.percentage}%</BoxContent>
                    </BoxItemProgress>
                    <BoxItemStartDate>
                        <BoxTitle>Start Date</BoxTitle>
                        <BoxContent>{ convertDate(onboarding.startDate)}</BoxContent>
                    </BoxItemStartDate>
                </BasicInfo>
                <Title>Onboarding Details</Title>
                { workflowTasks.length ? <BasicInfo>
                      <BoxItemPosition>
                        <BoxTitle>Workflow Name</BoxTitle>
                        <BoxContent>{onboarding.workflow}</BoxContent>
                        </BoxItemPosition>
                      </BasicInfo> : null }
                <WorkflowBox>
                    <TaskContainer>
                      <Title>Tasks</Title>
                      <CreateButtonComponent
                            textColor="#ffffff"
                            color="#2a7aeb"
                            text="+ Assign new task"
                            onClick={toggle} />
                    </TaskContainer>
                    <ModalComponent
                        isShowing={isShowing}
                        hide={modalToggle}
                        title= "Assign"
                    >
                       <AssignTaskFeature  boardingId={ boardingId } actionsList={actions} />
                    </ModalComponent>
                    {workflowTasks.length ? <>
                  
                        <WorkflowTable>
                            <WorkflowHead>
                            <TaskTitleHead>Task name</TaskTitleHead>
                            <TaskStatusHead>Status</TaskStatusHead>
                            <TaskStartDateHead>Assigned Date</TaskStartDateHead>
                            <TaskDueDateHead>Due Date</TaskDueDateHead>
                            <TaskAssigneeHead>Assigned to</TaskAssigneeHead>
                            <TaskActionHead>Edit</TaskActionHead>
                            </WorkflowHead>
                            { workflowTasks ? workflowTasks.map((item, i)=> {

                               return <WorkflowRow key={i}>
                                    <>
                                        <TaskTitle>{item.title}</TaskTitle>
                                        <TaskStatus>
                                            <StatusContainer status={item.taskStatus}>
                                                {toTitleCase(item.taskStatus)}
                                            </StatusContainer>
                                        </TaskStatus>
                                        <TaskStartDate>{convertDate(item.startDate)}</TaskStartDate>
                                        <TaskDueDate>{convertDate(item.dueDate)}</TaskDueDate>
                                        <TaskAssignee>{ item.assignee.firstName } {item.assignee.lastName}</TaskAssignee>
                                        <TaskAction>
                                            { item.taskStatus === 'COMPLETED' ?
                                                <RemoveDisabled>remove</RemoveDisabled> : <RemoveActive onClick={ () => removeAssigned(item.id) }>remove</RemoveActive> }
                                        </TaskAction>
                                    </></WorkflowRow>})  : null}
                        </WorkflowTable> </> : <EmptyBox message='There is no any tasks' />  }
                </WorkflowBox> 
            </BoardingContainer>: null }
            </>}
        </DashboardLayout>
    </>
}

export default OnboardingDetailsPage;
