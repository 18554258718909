import './styles/boarding-tab.style.css';
import { useState } from 'react';
import TabComponent from '../../../components/Tab/Tab.component';
import OnboardingFeature from '../onboarding/onboarding.feature';
import OffboardingFeature from '../offboarding/offboarding.feature';
import { TabsEnum } from '../onboarding/onboarding-table.feature';

const tabItems = [
    {
      id: 1,
      title: 'Onboardings',
      content: <OnboardingFeature tabType = { TabsEnum.ONBOARDING } title="Onboarding Invitation" showSearch={false} showControls={true} />,
    },
    {
      id: 2,
      title: 'Offboardings',
      content: <OffboardingFeature title="Start Offboarding" showSearch={false} />,
    },
  ];

interface Props {
    title?: string;
}

const BoardingTabFeature: React.FunctionComponent<Props> = ({ title }) => {
    const [active, setActive] = useState(1);
    return <>
        <div className="tabs">
        {tabItems.map(({ id, title }) =><TabComponent
           key={id}
           title={title}
           onItemClicked={() => setActive(id)}
           isActive={active === id}
         />
      )}
      </div>
      <div className="content">
        {tabItems.map(({ id, content }) => {
          return active === id ? <div key={id}>{content}</div> : ''
        })}
      </div>
    </>;
};
  

export default BoardingTabFeature;