import React from 'react';
import './popup.css';

export const Popup = ({ message, handleClose }) => {
    return (
        <div className="popup">
            <div className="popup_inner">
                <button onClick={handleClose} className="close-btn">X</button>
                <span>{message}</span>
            </div>
        </div>
    );
};