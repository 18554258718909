import styled from "styled-components"
import { getRandomColor } from "../../utils/randomColors";

const ColoredBox = styled.div<ColorProps>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: ${props => props.color};
    margin-right: 10px;
    color: white;
    font-size: 18px;
    display:flex;
    align-items: center;
    justify-content: center;
`;

const BoxWrapper = styled.div`
    display: flex;
    font-size: 18px;
    font-weight: 400;
`;

interface ColorProps {
    color: string;
}


function ProfileImageComponent({ firstName, lastName }) {
    const color = getRandomColor();
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase(); 
  return <>
    <BoxWrapper>
        <ColoredBox color={color}>{firstInitial} {lastInitial}</ColoredBox>
    </BoxWrapper>
  </>
};

export default ProfileImageComponent;
