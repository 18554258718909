import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/modal/modal.component';
import CreateNewPosition from '../../../features/positions/create-position.feature';
import useModal from '../../../hooks/useModal';
import DashboardLayout from '../../../layouts/DashboardLayout';
import { deletePosition, getPositions } from '../../../services/positions.service';
import { convertDate } from '../../../utils/date';
import './styles/positions.style.css';
import styled from "styled-components";
import SpinnerWrapper from "../../../components/Spinner/SpinnerWrapper.component";
import {useState} from "react";
import CreateButtonComponent from "../../../components/Buttons/CreateButton.component";

interface Props {
    children?: React.ReactNode
}

const StyledTh = styled.th`
    padding-left: 10px;
`;

const StyledTd = styled.td`
    padding-left: 10px;
`;

const StyledH1 = styled.h1`
    padding-left: 0;
    margin-left: 0;
`;

const PositionsPage: React.FunctionComponent<Props> = () => {
    const [positions, setPositions] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(()=> {
        async function getPositionsList() {
            setIsLoading(true);
            const positions = await getPositions();
            setPositions(positions);
            setIsLoading(false);
        }
        getPositionsList();
    },[]);

    const fetchPositions = async () => {
        setIsLoading(true);
        const positions = await getPositions();
        setPositions(positions);
        setIsLoading(false);
    }

    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    const handleDelete = async (id: string) => {
        await deletePosition(id);
        navigate('/settings/positions');
      };

    const {isShowing, toggle} = useModal();
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">settings page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> positions </div>
            </div>

            <div className="controls-page">
                <div>
                    <StyledH1>All Positions</StyledH1>
                </div>
                <div className="controls-list-page">
                    <CreateButtonComponent
                        textColor="#ffffff"
                        color="#2a7aeb"
                        onClick={toggle}
                        text="+ New Position"
                    />
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Position"
                >
                    <CreateNewPosition fetchPositions={fetchPositions} title = 'Create Position' />
                </ModalComponent>

            </div>
            { isLoading ? <SpinnerWrapper /> :
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>                    
                    <StyledTh>Name</StyledTh>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {positions.length ?  positions.map((item)=> {
                        return (<tr className="team-tr" key={item.id}>                        
                        <StyledTd>{item.name}</StyledTd>
                        <td>{item.description ? item.description : ' No description '}</td>
                        <td>{convertDate(item.createdAt)}</td>
                        <td><span onClick={() => handleNavigate(`/settings/positions/edit/${item.id}`)}>Edit</span>&nbsp;<span onClick={() => handleDelete(item.id)} >Delete</span></td>
                        </tr>)
                    }) :null}
                
                </tbody>
            </table> }
        </DashboardLayout>
    </>
}

export default PositionsPage;