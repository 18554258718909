import * as React from 'react';
import WorkflowTabFeature from '../../../features/workflows/tabs/WorkflowTab.feature';
import DashboardLayout from '../../../layouts/DashboardLayout';
import './styles/workflows.style.css';

interface Props {
    children?: React.ReactNode
}
const WorkflowPage: React.FunctionComponent<Props> = (props:Props) => {
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Workflow & Action Page</div>
            </div>

            <WorkflowTabFeature />
 
        </DashboardLayout>
    </>
}

export default WorkflowPage;