import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './pages/login/login.page';
import HomePage from './pages/home/home.page';
import TeamsPage from './pages/teams/teams.page';
import BoardingPage from './pages/boarding/boarding.page';
import WorkflowPage from './pages/workflow/workflows/workflow.page';
import TeamsDetailsPage from './pages/teams/teams-details.page';
import DepartmentsPage from './pages/departments/departments.page';
import ProtectedRoute from './ProtectedRoute';
import { userRoles } from './roles.constants';
import CreateAccountPage from './pages/create-account/createaccount.page';
import PositionsPage from './pages/settings/positions/positions.page';
import EmployeesPage from './pages/employees/employees.page';
import DepartmentsDetailsPage from './pages/departments/departments-details.page';
import EmployeeDetailsPage from './pages/employees/employee-details.page';
import OnboardingDetailsPage from "./pages/boarding/detials/boarding.details";
import EditActionPage from './pages/workflow/actions/action-edit.page';
import EditWorkflowPage from './pages/workflow/workflows/workflow-edit.page';
import EditTeamPage from './pages/teams/team-edit.page';
import EditPositionsPage from './pages/settings/positions/positions-edit.page'
import DepartmentsEditPage from './pages/departments/departments-edit.page';
import CatalogPage from "./pages/catalog/catalog.page";
import OffboardingDetailsPage from "./pages/boarding/detials/offboarding.details";
import CatalogDeatailsPage from "./features/catalogs/catalog-detail.page";
import ResetPasswordPage from './pages/resetPassword/resetPassword.page';
import ActiondDetailsPage from './pages/workflow/actions/action-details.page';
import UserSecurityPage from './pages/account/userSecurity.page';


const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/create-organization",
    element: <CreateAccountPage />
  },
  {
    path: "/reset",
    element: <ResetPasswordPage />
  },  
  {
    path: "/user/security",
    element: <UserSecurityPage/>
  },

  {
    path: "/dashboard",
    element:
        <ProtectedRoute expectedRole={[userRoles.editor]}>
          <HomePage />
        </ProtectedRoute>,
  },
  {
    path: "/employees",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EmployeesPage /></ProtectedRoute>,
  },
  {
    path: "/catalog",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><CatalogPage /></ProtectedRoute>,
  },
  {
    path: "/catalog/:catalogId",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><CatalogDeatailsPage /></ProtectedRoute>,
  },
  {
    path: "/employees/:employeeId/view",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EmployeeDetailsPage /></ProtectedRoute>,
  },
  {
    path: "/teams",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><TeamsPage /></ProtectedRoute>,
  },
  {
    path: "/teams/:teamId/view",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><TeamsDetailsPage /></ProtectedRoute>,
  },
  {
    path: "/team/edit/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EditTeamPage /></ProtectedRoute>,
  },
  {
    path: "/boarding",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><BoardingPage /></ProtectedRoute>,
  },
  {
    path: "/boarding/:boardingId",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><OnboardingDetailsPage /></ProtectedRoute>,
  },
  {
    path: "/offboarding/:boardingId",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><OffboardingDetailsPage /></ProtectedRoute>,
  },
  {
    path: "/workflow",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><WorkflowPage /></ProtectedRoute>,
  },
  {
    path: "/workflow/edit/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EditWorkflowPage /></ProtectedRoute>,
  },  
  {
    path: "/action/edit/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EditActionPage /></ProtectedRoute>,
  },
  {
    path: "/action/view/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><ActiondDetailsPage /></ProtectedRoute>,
  },  
  {
    path: "/departments",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><DepartmentsPage /></ProtectedRoute>,
  },
  {
    path: "/departments/:departmentId/view",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><DepartmentsDetailsPage /></ProtectedRoute>,
  },
  {
    path: "/departments/edit/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><DepartmentsEditPage /></ProtectedRoute>,
  },
  {
    path: "/settings",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><DepartmentsPage /></ProtectedRoute>,
  },
  {
    path: "/settings/positions",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><PositionsPage /></ProtectedRoute>,
  },
  {
    path: "/settings/positions/edit/:id",
    element: <ProtectedRoute expectedRole={[userRoles.editor]}><EditPositionsPage /></ProtectedRoute>,
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

reportWebVitals();
