import * as React from 'react';
import styled from "styled-components";
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import SearchBoxComponent from '../../components/SearchBox/SearchBox.component';
import ModalComponent from '../../components/modal/modal.component';
import useModal from '../../hooks/useModal';
import CreateOnboardingFeature from '../boarding/onboarding/flow/create-onboarding.feature';
import { getWorkflows } from '../../services/workflows.service';
import { getPositions } from '../../services/positions.service';
import { getTeams } from '../../services/team.service';
import AddEmployeeFeature from '../boarding/onboarding/flow/add-employee.feature';
import {useState} from "react";

const TableTitle = styled.div`
    font-weight: 600;
    margin-top: 30px;
    color: #474443;
`;

const Table = styled.table`
    width: 100%;
    padding: 10px;
    margin: 0;
    border-collapse: collapse;
    margin-top: 30px;
`;

const Thead = styled.thead`
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 10px;
    height: 40px;
    color: #121111;
    border: 1px solid #e0e0e0;
    font-size: 13px;
`;

const ThItem = styled.th`
    text-align: center;
    min-width: 30px;
`;

const Fullname = styled.th`
    width: 220px;
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 10px;
`;

const ControlButtons = styled.div`
    display: flex;
`;

export enum TabsEnum {
    ONBOARDING,
    OFFBOARDING,
    EMPLOYED
}

interface Props {
    title?: string;
    children?: React.ReactNode | null;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
    sendDataToParent?: any;
    fetchEmployees?: () => void;
    fetchOnboardings?: () => void;
}
const EmployeeTable: React.FunctionComponent<Props> = ({ title, children, showTitle, showControls, showSearch, fetchEmployees, fetchOnboardings }) => {
    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const [positions, setPositions] = React.useState<any[]>([]);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [teams, setTeams] = React.useState<any[]>([]);
    const {isShowing, toggle} = useModal();
    const [activeModal, setActiveModal] = useState<string | null>(null);
    React.useEffect(() => {
        async function getTeamsList() {
            const teams = await getTeams();
            setTeams(teams);
        }
        getTeamsList();
    }, []);

    React.useEffect(() => {
        async function getPositionsList() {
            const positions = await getPositions();
            setPositions(positions);
        }
        getPositionsList();
    }, []);

    React.useEffect(() => {
        async function getChapterList() {
            const workflows = await getWorkflows();
            setWorkflows(workflows.data);
        }
        getChapterList();
    }, []);

    return <>
        <HeadingWrapper>
            {showTitle ? <div><TableTitle>{ title }</TableTitle></div> : null }
            {showSearch ? <SearchBoxComponent placeholder="Search by name..." /> : null }
            <ControlButtons>
            {title && showControls ?
                <CreateButtonComponent
                    textColor="#2a7aeb"
                    color="#ffffff"
                    border="1px solid #2a7aeb"
                    text="Add existing employee"
                    onClick={() => {
                        toggle();
                        setActiveModal('addEmployee');
                    }}
                />
            : null }
            {title && showControls ?
            <CreateButtonComponent
                textColor="#ffffff"
                color="#2a7aeb"
                text="+ New onboarding"
                onClick={() => {
                    toggle();
                    setActiveModal('createInvitation');
                }}
            /> : null }
            </ControlButtons>
        </HeadingWrapper>
        {
            activeModal === 'createInvitation' && (
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="Create a new onboarding"
                    isExpanded={isExpanded}
                >
                    <CreateOnboardingFeature fetchOnboardings={fetchOnboardings} setIsExpanded={setIsExpanded} teams={teams} positions={positions} workflows={workflows} />
                </ModalComponent>
            )
        }
        {
            activeModal === 'addEmployee' && (
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="Add existing employee"
                >
                    <AddEmployeeFeature fetchEmployees={fetchEmployees} teams={teams} positions={positions} workflows={workflows} />
                </ModalComponent>
            )
        }
        <Table cellPadding="0" cellSpacing="0">
            <Thead>
                <tr>
                    <ThItem></ThItem>
                    <Fullname>Employee</Fullname>                    
                    <th>Department Lead</th>
                    <th>Team</th>
                    <th>Status</th>
                    <th>Created at</th>
                </tr>
            </Thead>
            <tbody>
                 { children }
            </tbody>
        </Table>
    </>
}

export default EmployeeTable;
