import * as React from 'react';
import { useParams } from 'react-router-dom';
import ModalComponent from '../../components/modal/modal.component';
import AddTeamMemberFeature from '../../features/boarding/onboarding/flow/add-team-member.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import {getTeamById, removeEmployeeFromTeam} from '../../services/team.service';
import { getUsers } from '../../services/user.service';
import { convertDate } from '../../utils/date';
import './styles/teams.style.css';
import styled from "styled-components";
import ProfileImageComponent from "../../components/ProfileImage/ProfileImage";


interface Props {
    children?: React.ReactNode
}

const TeamMemberTable = styled.table`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Thead = styled.thead`
  width: 100%;
  display: flex;
  font-weight: 600;
  background-color: #f0f0f0;
  height: 50px;
  align-items: center;
  color: #121111;
  border: 1px solid #e0e0e0;
  font-size: 13px;
`;

const HeadTrow = styled.tr`
  width: 100%;
`;

const HeadTh = styled.th`
  width: 100%;
  padding-left: 20px;
`;

const Tbody = styled.tbody`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TeamTdProfile = styled.td`
  width: 100px;
  padding-left: 20px;
`;

const TeamTr = styled.tr`
  width: 100%;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  align-items: center;
`;

const TeamTd = styled.td`
  text-align: left;
  width: 100%;
  padding-left: 20px;
`;

const RemoveButton = styled.div`
  cursor: pointer;
  margin-right: 20px;  
`;

const SectionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const Fname = styled.div`
    padding: 5px 5px 5px 10px;
`;

const RemoveHead = styled.div`
    padding-right: 20px;
`;

const FirstName = styled.td`
    padding: 10px 10px 10px;
`;

const FnameSubItem = styled.div`
    padding-left: 10px;
    font-size: 13px;
    color: #ababab;
    padding-bottom: 7px;
`;

const ShortBox = styled.div`
   width: auto;
`;

const TeamsDetailsPage: React.FunctionComponent<Props> = () => {
    
    const [employees, setEmployees] = React.useState<any[]>([]);   
    const [currentTeam, setCurrentTeam] = React.useState<any>();   

    let {teamId} = useParams();

    const fetchTeamDetails = async () : Promise<void> => {
        const team = await getTeamById(teamId);       
        setCurrentTeam(team);
    }

    React.useEffect(() => {
        async function getTeamDetails(){
            const team = await getTeamById(teamId);       
            setCurrentTeam(team);
        }       
        getTeamDetails();
    }, [teamId]);    

    async function removeEmployeeTeam(teamId: string, employeeId: string) {
        await removeEmployeeFromTeam(teamId, employeeId);
        await fetchTeamDetails();           
    }


    React.useEffect(() => {
        async function getEmployees() {
            const response = await getUsers();           
            setEmployees(response.users);
        }
        getEmployees();
    }, []); 

    const {isShowing, toggle} = useModal();
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Teams Page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> { currentTeam ? currentTeam.name : null } </div>
            </div>
            <ShortBox>
            <div className="controls-page">
                <div>
                    <h1>Team Details</h1>
                </div>
                <div className="controls-list-page">
                    <div className="controls-new-team" onClick={toggle}>+ Add Member</div>
                </div>
            </div>
            {currentTeam ? <>
                <ModalComponent isShowing={isShowing} hide={toggle} title="Add Member">
                    <AddTeamMemberFeature teamId={currentTeam.id} teamMembers={currentTeam.members} employees={ employees } fetchEmployees={fetchTeamDetails} />
                 </ModalComponent>
            
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>                    
                    <th>Name</th>
                    <th>Status</th>
                    <th>Team Lead</th>                                                           
                    <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="team-tr" key={currentTeam.id}>                    
                    <FirstName>{currentTeam.name}</FirstName>
                    <td>{currentTeam.status}</td>
                    <td>{currentTeam.teamLead.firstName} {currentTeam.teamLead.lastName}</td>                                                     
                    <td>{ convertDate(currentTeam.createdAt)}</td>
                </tr>
                </tbody>
            </table>

                <TeamMemberTable>
                    <Thead>
                        <HeadTrow>
                            <HeadTh>Employee</HeadTh>
                            <HeadTh><RemoveHead>Remove</RemoveHead></HeadTh>
                        </HeadTrow>
                    </Thead>
                    <Tbody>
                    { currentTeam.members.map((item: any, i: number) => {
                        return <TeamTr key={i}>
                            <TeamTdProfile>
                                <ProfileImageComponent firstName={ item.firstName } lastName = { item.lastName || ''} />
                            </TeamTdProfile>
                            <TeamTd> <SectionItem><Fname>{ item.firstName } { item.lastName }</Fname> <FnameSubItem>{ item.position || '-' }</FnameSubItem></SectionItem></TeamTd>
                            <TeamTd> <RemoveButton onClick={() => removeEmployeeTeam(teamId, item.id)}>Remove</RemoveButton> </TeamTd>
                        </TeamTr>
                    }) }
                    </Tbody>
                </TeamMemberTable>
                </>
                : null }
                </ShortBox>
        </DashboardLayout>
    </>
}

export default TeamsDetailsPage;
