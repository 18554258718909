import * as React from 'react';
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../layouts/DashboardLayout';
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../components/input/InputText.component";
import { updateTeam } from "../../services/team.service";
import { getUsers } from "../../services/user.service";
import { getTeamById } from '../../services/team.service';
import LinkButton from '../../components/LinkButton/LinkButton.component';
import Button from '../../components/Buttons/Button.component';

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:10px;
    flex-wrap: nowrap;
    margin-top: 15px;
`;

function EditTeamPage() {
          
    const  statuses = [{value: "ACTIVE", label: "Active"}, {value: "NOT_ACTIVE", label: "Inactive"}];
    const [teamName, setTeamName] =  useState('');    
    
    const [team, setTeam] = useState<any>(() => null);

    const [teamLead, setTeamLead] =  useState<any>();
    const [teamStatus, setTeamStatus] =  useState<any>();

    const [userOptions, setUserOption] = useState<any[]>(() => []);
    const navigate = useNavigate();
   

    let { id  } = useParams();
    React.useEffect(() => {      
        async function getTeam() {            
            var team = await getTeamById(id);                
            setTeam(team); 
            setTeamName(team.name);
            setTeamLead(team.teamLead.id);   
            setTeamStatus(team.status);         
        }
        getTeam();
        
    },[id]);

    React.useEffect(() => {
        async function getUserList() {          
            var usersResponse = await getUsers();
            const userOptions = usersResponse.users.map((item)=> { return {value: item.id, label: `${item.firstName} ${item.lastName}`  }} );     
            setUserOption(userOptions);          
        }        
        getUserList();
    },[]);


    const handleUserOptionChange = (option: any) => {       
        setTeamLead(option.value);
    };

    const handleStatusOptionChange = (option: any) => {        
        setTeamStatus(option.value);
    };  

    const handleTeamNameChange = (event: any) => {
        setTeamName(event.target.value);        
    };
   
    const handleSubmit = async ()=> {                
        try{
            await updateTeam(id, { name: teamName, teamLeadId: teamLead, status: teamStatus });
            navigate('/teams');
        }
        catch(err){          
            console.log(err);
        }
    }    

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Team edit page</div>                
            </div>
            
    {(team != null && userOptions.length > 0)?
    <Form>    
    <FormItem>
        <InputTextComponent type='text' 
                            label="Name" 
                            placeholder="type a name" 
                            fullWidth={true} 
                            value={teamName} 
                            onChildStateChange = {handleTeamNameChange  }  />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Team lead"  
                           options={userOptions} 
                           selectedOption={userOptions.find((item) => item.value === teamLead)} 
                           onChange={handleUserOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Status"  
                           options={statuses}  
                           selectedOption={statuses.find((item) => item.value === teamStatus)} 
                           onChange={handleStatusOptionChange} />
    </FormItem>    
    <FormButtonsItem>
        <Button  text='Save'  color='#2a7aeb' onClick={ () => handleSubmit() } />         
        <LinkButton onClick={ () => navigate('/teams') } text='Cancel'></LinkButton>              
    </FormButtonsItem>     
    </Form> : null }
    </DashboardLayout>
  </>
};

export default EditTeamPage;