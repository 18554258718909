import * as React from 'react';
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../layouts/DashboardLayout';
import { convertDate } from '../../utils/date';
import './styles/departments.style.css';
import {getDepartment} from "../../services/departments.service";
interface Props {
    children?: React.ReactNode
}
const DepartmentsDetailsPage: React.FunctionComponent<Props> = (props:Props) => {

    const [currentChapeter, setCurrentChapter] = React.useState<any>();

    let { departmentId } = useParams();
    React.useEffect(() => {
        async function getChapterDetails() {
            const department = await getDepartment(departmentId);
            setCurrentChapter(department);
        }
        getChapterDetails();
    }, [departmentId]);
   
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Departments Page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> { currentChapeter ? currentChapeter.name : null } </div>
            </div>

            <div className="controls-page">
                <div>
                    <h1>Chapter Details</h1>
                </div> 

            </div>
            {currentChapeter ?
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>
                    
                    <th>Name</th>
                    <th>Status</th>
                    <th>Lead</th>
                    <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="team-tr" key={currentChapeter.id}>                   
                    <td>{currentChapeter.name}</td>
                    <td>{currentChapeter.status}</td>
                    <td>{currentChapeter.chapterLead.firstName} {currentChapeter.chapterLead.lastName}</td>
                    <td>{ convertDate(currentChapeter.createdAt)}</td>
                </tr>
                </tbody>
            </table> : null }
        </DashboardLayout>
    </>
}

export default DepartmentsDetailsPage;
