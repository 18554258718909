import './logo.style.css'

const LogoComponent = () => {
    const user = localStorage.getItem("user");
    const obj = JSON.parse(user!);
    return <>
        <h1>Rocket Desert</h1>
        <span className="organization">{obj.organization.name}</span>
    </>
}

export default LogoComponent;
