export interface OptionsItem {
    label: string;
    value: string | number;
}

export interface Employee {
    id: string | number;
    firstName: string;
    lastName: string;
}

export function arrayDifference(arr1: OptionsItem[], arr2: OptionsItem[]): OptionsItem[] {
    return arr1.filter(item => !arr2.find(p => p.value === item.value));
}

export function mapToOption(employees: Employee[]): OptionsItem[] {
    return employees.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
}