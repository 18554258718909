import * as React from 'react';
import styled from "styled-components";
import SearchBoxComponent, {EmptySearch} from '../../../components/SearchBox/SearchBox.component';

const TableTitle = styled.table`
    font-weight: 600;
    margin-top: 30px;
    color: #474443;
`;

const Table = styled.table`
    width: 100%;
    padding: 10px;
    margin: 0;
    border-collapse: collapse;
    margin-top: 10px;
`;

const Thead = styled.thead`
    font-weight: 600;
    background-color: #f0f0f0;
    padding: 10px;
    height: 40px;
    color: #121111;
    border: 1px solid #e0e0e0;
    font-size: 13px;
`;

const Fullname = styled.th`
    width: 220px;
    text-align: left;
    padding-left: 10px;
`;

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 10px;
`;

interface Props {
    title?: string;
    children?: React.ReactNode;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;    
}
const ActionsTable: React.FunctionComponent<Props> = ({ title, children, showTitle, showSearch }) => {     
    return <>
        <HeadingWrapper>
            {showTitle ? <div><TableTitle>{ title }</TableTitle></div> : null }
            {showSearch ? <SearchBoxComponent placeholder="Search by workflow name..." /> : <EmptySearch /> }

        </HeadingWrapper>       
        
        <Table cellPadding="0" cellSpacing="0">
            <Thead>
                <tr>                    
                    <Fullname>Name</Fullname>
                    <th>Status</th>
                    <th>Owner</th>
                    <th>Revertible</th>
                    <th>Action Type</th>
                    <th>Created at</th>
                    <th>Actions</th>
                </tr>
            </Thead>
            <tbody>
            { children }
            </tbody>
        </Table>
    </>
}

export default ActionsTable;
