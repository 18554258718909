export function calculateDaysLeft(startDate: Date | string): number {
    // Create a new Date from startDate and remove time component
    let startDateAsDate: Date = (startDate instanceof Date) ? startDate : new Date(startDate);
    startDateAsDate = new Date(startDateAsDate.getFullYear(), startDateAsDate.getMonth(), startDateAsDate.getDate());

    // Get the current date and remove time component
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);

    // Calculate difference in days
    const diff: number = startDateAsDate.valueOf() - currentDate.valueOf();
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
}