import './css/indexLayout.css';

interface Props {
    children: React.ReactNode
}

const IndexLayoutPage: React.FunctionComponent<Props> = (props:Props) => {  

  return (
    <div className="wrapper">
      <div className="leftSideWrapper">
          <div className="sideWrapper">
          <div className="logo">
              Rocket Desert
          </div>
          <div className="description">
            Maximizing Business Potential through Superior Onboarding Experiences
          </div>
          </div>
      </div>
      <div className="rightSide">
        <div className="rightSideWrapper">
            {props.children}
          </div>
      </div>
      <div className="copyright">&copy; 2023 - 2024 Rocket Desert, Inc. All rights reserved
        <a className="legalinks" href="https://rocketdesert.com/terms-of-service">Terms & Conditions</a> and
        <a className="legalinks" href="https://rocketdesert.com/privacy-policy">Privacy Policy</a>
    </div>

    </div>
  );
}

export default IndexLayoutPage;
