import { apiClient } from "../infrastructure/api/apiClient";

export const getOnboardings = async () => {
    try {
        return await apiClient.get('/boarding/onboardings').then(item=> item.data);
    } catch (error) {
        throw error;
      }
};

export const getOffboardings = async () => {
    try {
        return await apiClient.get('/boarding/offboardings').then(item=> item.data);
    } catch (error) {
        throw error;
      }
};

export const getOnboardingById =  (onboardingId: string) => {
    return  apiClient.get(`/boarding/onboarding/${onboardingId}/details`);
}

export const getOffboardingById =  (onboardingId: string) => {
    return  apiClient.get(`/boarding/offboarding/${onboardingId}/details`);
}

export const createOnboarding = (payload: any) => {
    return apiClient.post('/boarding/create-onboarding', payload);
};

export const addEmployee = (payload: any) => {
    return apiClient.post('/user/invite-user', payload);
};

export const startOffboarding = (payload: any) => {
    return apiClient.post('/boarding/start-offboarding', payload);
};
