import styled from "styled-components"

const InputForm = styled.div`
    margin-top: 20px;
    width: 100%;
`
const InputLabel = styled.div`
    color: #515251;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
`;

const InputWrapper = styled.div``;

const InputText = styled.input<InputTextProps>`
    width: ${props => props.fullWidth ? '100%' : '400px'};
    height: 30px;
    font-size: 14px;
    border: 1px solid #ebebeb;
    padding-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

interface InputTextProps{
    label?: string;
    type: string;
    placeholder?: string;
    fullWidth?: boolean;
    onChildStateChange?: any;
    value?: string;
    autoComplete?: string; 
 }

const InputTextComponent:React.FC<InputTextProps> = ({ label, type, placeholder, fullWidth, onChildStateChange, value, autoComplete })=> {
    let input;

    const today = new Date();
    const dateString = today.toISOString().split('T')[0];

    if (type === "date") {
        input = <InputText label={label} fullWidth={fullWidth} onChange={onChildStateChange} type={type} placeholder={placeholder} value={value} min={dateString}  autoComplete={autoComplete}/>;
    } else {
        input = <InputText label={label} fullWidth={fullWidth} onChange={onChildStateChange} type={type} placeholder={placeholder} value={value}  autoComplete={autoComplete}/>;
    }

    return (
        <InputForm>
            {label ? <InputLabel>{label}</InputLabel> : null }
            <InputWrapper>
                {input}
            </InputWrapper>
        </InputForm>
    );
}

export default InputTextComponent;
