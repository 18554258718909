import {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import InputTextComponent from '../../components/input/InputText.component';
import { login } from '../../services/auth.service';
import './styles/login.css';
import LinkButton from '../../components/LinkButton/LinkButton.component';
import IndexLayoutPage from '../../layouts/IndexLayout';


const ErrorWrapper = styled.div`
  width: auto;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
    const handleSubmitfunc = async () => {
        try {
            await login(email, password).then((response) => {
                if (response.status === 429) {
                    console.log("Too many requests.");
                    setError(true);
                }

                if (!response.accessToken) {
                    setError(true);
                } else {
                    navigate('/dashboard');
                }
            }).catch(error => {
                console.log(error);
                setError(true);
            });

        } catch(e) {
            console.error(e);
            setError(true);
        }
    }

  const handleChildStateChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleChildStateChangePassword  = (event: any) => {
    setPassword(event.target.value);
  };


  return (    
      
      <IndexLayoutPage>            
              <div className="form">
              <div className="Texthead">
                <h3>Welcome to Rocket Desert</h3>
                <span>Its organization dashboard</span>
              </div>
              <div className="responsive-head">
                <h3>Welcome to Rocket Desert</h3>
                <span>Its organization dashboard</span>
              </div>
                  <div className='inputForm'>
                    <InputTextComponent  onChildStateChange={handleChildStateChange}  label="Organization Email" type="text" />
                  </div>
                  <div className='inputForm'>
                    <InputTextComponent onChildStateChange={handleChildStateChangePassword}  label="Password" type="password" />
                  </div>                  

                  <div className="forgotPassword">
                      <LinkButton text='Forgot Password?' onClick={ () => navigate('/reset') }  />
                  </div>

                  <div className="errorMessage">
                    {error ? <ErrorWrapper>Please use correct credentials!</ErrorWrapper> : null}
                  </div>

                  <div className="loginButton">
                      <div className="loginBtn" onClick={handleSubmitfunc}>Sign In</div>
                  </div>

                  
                  <div className="getAccess">
                      <div>Don't have an account yet?</div>&nbsp;<div className='link' onClick={ () => navigate('/create-organization') }> Create  </div>&nbsp;<div>an organization account</div>
                  </div>
              </div>
        
      </IndexLayoutPage>
    
  );
}

export default LoginPage;
