import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CreateButtonComponent from '../../components/Buttons/CreateButton.component';
import ModalComponent from '../../components/modal/modal.component';
import NewTeamFeature from '../../features/teams/create-team.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getTeams } from '../../services/team.service';
import { getTeamLeaders } from '../../services/user.service';
import { convertDate } from '../../utils/date';
import './styles/teams.style.css';
import styled from "styled-components";
import SpinnerWrapper from "../../components/Spinner/SpinnerWrapper.component";
import {useState} from "react";
import { EmptyBox } from '@rocket-desert/shared-ui';
interface Props {
    children?: React.ReactNode
}

const TeamTh = styled.th`
    padding-left: 10px;
`;

const TeamTd = styled.td`
    padding-left: 10px;
    font-weight: lighter;
`;

const StyledH1 = styled.h1`
    padding-left: 0;
    margin-left: 0;
`;

const TeamsPage: React.FunctionComponent<Props> = () => {
    const [leaders, setLeaders] = React.useState<{id: number | string; firstName: string}[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(()=> {
        async function getLeaders() {
            const users = await getTeamLeaders();
            setLeaders(users.users);
        }
        getLeaders();
    },[]);

    const fetchTeams = async () => {
        setIsLoading(true);
        const teams = await getTeams();
        setTeams(teams);
        setIsLoading(false);
    }

    React.useEffect(()=> {
        async function getTeamsList() {
            setIsLoading(true);
            const teams = await getTeams();
            setTeams(teams);
            setIsLoading(false);
        }
        getTeamsList();
    },[]);     

    const {isShowing, toggle} = useModal();
    const navigate = useNavigate();
    const handleNavigate = async (url: string) => {
        navigate(url);
    }

    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Teams Page </div>
            </div>

            <div className="controls-page">
                <div>
                    <StyledH1>All Teams</StyledH1>
                </div>
                <div className="controls-list-page">
                    <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="+ New team" onClick={toggle} />
                </div>
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="New Team"
                >
                   {leaders.length? <NewTeamFeature fetchTeams={fetchTeams} users={leaders} title = 'Create Team' /> : <EmptyBox message={'There is no any employee find who will  be assign as team lead'}></EmptyBox> }
                </ModalComponent>

            </div>
            {isLoading ? <SpinnerWrapper /> :
            <table cellPadding="0" cellSpacing="0" className="team-table">
                <thead className="team-table-row team-table-heading">
                    <tr>                    
                    <TeamTh>Name</TeamTh>
                    <th>Status</th>
                    <th>Lead</th>                    
                    <th>Created at</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                { teams ? teams.map((item)=> {
                    return <tr className="team-tr" key={item.id} onClick = { () => handleNavigate(`${item.id}/view`) }>                   
                    <TeamTd>{item.name}</TeamTd>
                    <td>{item.status}</td>
                    <td>{item.teamLead.firstName} {item.teamLead.lastName}</td>                   
                    <td>{ convertDate(item.createdAt) }</td>
                    <td><span onClick={(e) => { e.stopPropagation(); handleNavigate(`/team/edit/${item.id}`);}}>Edit</span></td>
                </tr>
                }) : null }
                </tbody>
            </table> }
        </DashboardLayout>
    </>
}

export default TeamsPage;