import React, {useEffect, useState} from "react";
import styled from "styled-components";
import RemoveImage from "../../assets/svg/gray/icons8-trash.svg";

const DropdownSelectBoxWrapper = styled.div`
  width: 100%;
  height: 30px;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const SelectedOption = styled.div`
  font-family: sans-serif;
  padding-top: 7px;
`;

const Options = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-left: -11px;
  padding-top: 7px;
  width: 70%;
  margin-top: 6px;
  z-index: 999;
`;

const ResultWrapper = styled.div`
  white-space: nowrap;
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
`;

const Option = styled.div`
  padding: 15px;
  &:hover {
    background: #ebebeb;
    cursor: pointer;
  }
`;

const EmptyOption = styled.div`
  padding: 15px;
  font-family: sans-serif;
  font-size:12px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #515251;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Item = styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background-color: #e3e5e8;
  align-items: center;
  padding-left: 10px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const RemoveImageItem = styled.img`
  opacity: 0.5;
  width: 12px;
`;

const ItemName = styled.div`
  padding: 5px 3px;
`;
const ItemClose = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5px;
`;

const ItemAction = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  -webkit-box-align: center;
`;

const VerticalLineDiv = styled.div`
  font-size: 12px;
  border-left: 1px solid #6e696c;
  height: 50%;
`;

const ArrowDown = styled.div` 
  border: solid #515251;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  float: right; 
  margin-top:3px;
`;

const ArrowUp = styled.div` 
  border: solid #515251;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: relative;
  float: right;
  margin-top:5px;
`;

interface DropdownSelectBoxProps {
  options: { value: number | string; label: string }[];
  selectedOptions?: { value: number | string; label: string }[];
  label?: string;
  onSelect: (selectedOptions: any, option: any) => void;
  onRemove: (selectedOptions: any, option: any) => void;
}

const MultiSelectBox: React.FC<DropdownSelectBoxProps> = ({
  options,
  selectedOptions,
  label,
  onSelect,
  onRemove,
}) => {  
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionsList, setSelectedOptionsList] =
      useState<any[]>([]);
  const [filteredOptionsList, setfilteredOptionsList] = useState<any[]>([]);
  useEffect(() => {
    const filtered = options.filter(
        (item) =>
            !selectedOptions.find((e) =>
                e.value === item.value
            ));
    setfilteredOptionsList(filtered);
  }, [options, selectedOptions]);

  useEffect(() => {   
    setSelectedOptionsList(selectedOptions);
  }, [selectedOptions]);

  const handleOptionClick = (option: any) => {
    const newArray = [
      ...selectedOptionsList,
      { value: option.value, label: option.label },
    ];
    setSelectedOptionsList(newArray);
    const items = options.filter(
      (item) => !newArray.find((e) => e.value === item.value)
    );
    setfilteredOptionsList(items);
    onSelect(newArray, option);
    setIsOpen(false);
  };

  const removeAction = (option: any) => {
    const selectedItems = selectedOptionsList.filter(
      (item) => item.value !== option.value
    );
    setSelectedOptionsList(selectedItems);
    const items = options.filter(
      (item) => !selectedItems.find((e) => e.value === item.value)
    );
    setfilteredOptionsList(items);
    onRemove(selectedItems, option);
  };  

  return (
    <>
      <Label>{label}</Label>
      <DropdownSelectBoxWrapper>
        <SelectedOption onClick={() => setIsOpen(!isOpen)}>
           select items...
          {isOpen ?  <ArrowUp/> : <ArrowDown/>}
        </SelectedOption>
        {isOpen && (
          <Options>
            <ResultWrapper>
               {filteredOptionsList.length? filteredOptionsList.map((option) => (
                <Option
                  key={option.value}
                  onClick={() => handleOptionClick(option)}>
                  {option.label}
                </Option>
              )) : <EmptyOption>There is no any items...</EmptyOption>}
            </ResultWrapper>
          </Options>
        )}
      </DropdownSelectBoxWrapper>
      <ItemsWrapper>
        <ItemList>
          {selectedOptionsList && selectedOptionsList.length
            ? selectedOptionsList.map((item: any) => {
                return (
                  <>
                    <Item key={item.value}>
                      <ItemName>{item.label}</ItemName>
                      <ItemClose
                        onClick={(e) => {
                          e.stopPropagation();
                          removeAction(item);
                        }}
                      >
                        <VerticalLineDiv />
                        <ItemAction>
                          <RemoveImageItem src={RemoveImage} />
                        </ItemAction>
                      </ItemClose>
                    </Item>
                  </>
                );
              })
            : null}
        </ItemList>
      </ItemsWrapper>
    </>
  );
};

export default MultiSelectBox;
