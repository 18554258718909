import * as React from 'react';
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../../layouts/DashboardLayout';
import { getActionDetails, getActionAssessments } from '../../../services/actions.service';
import Label from '../../../components/Label/Label.component'
import ActionDetailsTabFeature from '../../../features/workflows/tabs/ActionDetailsTab.featue';
import {ActionType, getReadableActionType} from "../../../utils/action-types.utils";
import {transformRevertibleString} from "../../../utils/revertible-format";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:30px;
    flex-wrap: nowrap;
    margin-top: 15px;
    border-bottom : 1px solid  lightgray;
    padding-bottom: 20px;
`;

function ActiondDetailsPage() {  
    
    const [action, setAction] = useState<any>(() => null);        
    const [assessmentsData, setAssessmentsData] = useState<any>(() => null);   
   

    let { id } = useParams();
    React.useEffect(() => {      
        async function getAction() {            
            var action = await getActionDetails(id);                
            setAction(action);            
        }
        getAction();
        
    },[id]); 
    
    React.useEffect(() => {      
        async function getAssessments() {            
            const response = await getActionAssessments(id);
            setAssessmentsData(response);            
        }
        getAssessments();
        
    },[id]);
       

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Actions</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Action details page</div>                
            </div>
            
    {(action) &&
    <Form>  
    <FormButtonsItem>                
        <Label label="Name" text={action.name}/>
        <Label label="Assign To" text={`${action.assignee.firstName} ${action.assignee.lastName}`} />
        <Label label="Action Type" text={getReadableActionType(action.actionType as ActionType)} />
        <Label label="Reversible Type" text={transformRevertibleString(action.reversibleType)} />
    </FormButtonsItem>      
    <FormItem>        
    </FormItem>    
    { action.description &&
    <FormItem>
        <Label label="Description" text={action.description} />
    </FormItem>   }  
    { (action.actionType === 'DOCUMENTATION_TRAINING') ?
    < ActionDetailsTabFeature files={action.files}  assessmentsData={assessmentsData} actionId={action.id}    />
    : null
    }
    </Form> }
    </DashboardLayout>
  </>
}

export default ActiondDetailsPage;