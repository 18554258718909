import { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../components/input/InputText.component";
import SentComponent from "../../components/Sent/Sent.component";
import {createDepartment} from "../../services/departments.service";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    width: 100px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    users?: any[];
    fetchDepartments?: () => void;
 }

const NewDepartmentsFeature:React.FC<InputTextProps> = ({  users, fetchDepartments })=> {
    const [notSent, setnotSent] = useState(true);
    const [chapterName, setChapterName] =  useState('');
    const handleChapterName = (event: any) => {
      setChapterName(event.target.value);
    };
    const handleSubmit = async()=> {
        setnotSent(false);
        await createDepartment({ name: chapterName, chapterLead: chapterLeader });
        fetchDepartments && fetchDepartments();
    }
    const options = users.map((item) => {
      return {value: item.id, label:  `${item.firstName} ${item.lastName}` };
    });
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [chapterLeader, setChapterLeader] = useState(options[0].value);
    const handleOptionChange = (option: any) => {
        setChapterLeader(option.value);
        setSelectedOption(option);
    };
  return <>
    {notSent ? <div><FormItem>
        <InputTextComponent onChildStateChange = {handleChapterName} fullWidth={true} label="Name" type="text"  placeholder="Department name" />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Department head" options={options} selectedOption={selectedOption} onChange={handleOptionChange} />
    </FormItem>
    <FormItem>
        <Button  onClick = { () => handleSubmit() }>Create</Button>
    </FormItem> </div> : <SentComponent title = "New department has been created!" /> }
  </>
};

export default NewDepartmentsFeature;
