export const WorkTypeList: {id: string, name: string}[] = [
    {
        id: "OFFICE",
        name: "Office"
    },
    {
        id: "REMOTE",
        name: "Remote"
    },
    {
        id: "HYBRID",
        name: "Hybrid"
    }
]
