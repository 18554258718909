import {  useState } from 'react';
import DropdownSelectBox from '../../components/DropdownSelectBox/DropdownSelectBox.component';
import InputTextComponent from '../../components/input/InputText.component';
import { CreateOrganization } from '../../services/account.service';
import { industriesList } from '@rocket-desert/common-lib';
import PasswordStrengthComponent
 from '../../components/PasswordStrength/PasswordStrength.component';
 import { PasswordStrengthStatuses }  from "../../components/PasswordStrength/PasswordStrength.component";
 import { InfoMessageComponent, MessageTypes } from '../../components/InfoMessage/InfoMessage.component';
 import IndexLayoutPage from '../../layouts/IndexLayout';
 import './styles/account.css';


function CreateAccountPage() {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const industryList = industriesList.length ? industriesList.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Industry"}];
  const [selectedIndustryOption, setSelectedIndustryOption] = useState(industryList[0]);
  const [industryValue, setIndustryValue] = useState(industryList[0].value);
  const [errorMessage, setErrorMessage] = useState(''); 
  const [showError, setShowError] = useState(false); 
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordStrengthStatus, setPasswordStrengthStatus] = useState<PasswordStrengthStatuses>();

  const handleSubmitfunc = async () => {
    hideErrorMessage();
    hideSuccessMessage();  

    if(!name || !email || !password )
    {
        showErrorMessage('All fields is required!');
        return;
    }

    if(passwordStrengthStatus !== PasswordStrengthStatuses.STRONG)
    {
        showErrorMessage('You does not set a strong password!');
        return;
    }        
    
    await CreateOrganization({email, password, name, industry: industryValue})
        .then(() => setShowSuccess(true) )
        .catch(() => showErrorMessage('Account registration failed!')); 
  }

  const handleNameChange = (event: any) => {
    setName(event.target.value);
    hideErrorMessage();
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    hideErrorMessage();
  };

  const handlePasswordChange  = (event: any) => {
    setPassword(event.target.value);
    hideErrorMessage();
  };

  const handleIndustryChange = (option: any) => {
    setSelectedIndustryOption(option);
    setIndustryValue(option.value);
    hideErrorMessage();
};
  
const showErrorMessage = (message: string) => {
  setErrorMessage(message);
  setShowError(true);
}

const hideErrorMessage = () => {
  setErrorMessage('');
  setShowError(false);
}

const hideSuccessMessage = () => {
  setShowSuccess(false);
}

const handlePasswordStrengthCheck = async (status: PasswordStrengthStatuses ) => {     
  setPasswordStrengthStatus(status);
}


  return (


    <IndexLayoutPage>
              <div className="Texthead">
                <h3>Create Rocket Desert Account</h3>                
              </div>
              <div className="responsive-head">
                <h3>Create Rocket Desert Account</h3>                
              </div>              
             
              <div className='form'>
              {(!showSuccess)? <>
                  <form autoComplete='off'>
                  <div className='form-item'>
                    <InputTextComponent  onChildStateChange={handleNameChange}  label="Organization name" type="text" />
                  </div>
                  <div className='form-item'>
                    <InputTextComponent  onChildStateChange={handleEmailChange}  label="Organization Email" type="text" />
                  </div>
                  <div className='form-item'>
                    <DropdownSelectBox label="Choose Industry" options={industryList} selectedOption={selectedIndustryOption} onChange={handleIndustryChange} />
                  </div>
                  <div className='form-item'>
                    <InputTextComponent onChildStateChange={handlePasswordChange}  label="Password" type="password" autoComplete='new-password' />                                                
                    { password ? <PasswordStrengthComponent password={password} onScoringFinished={handlePasswordStrengthCheck}/> : null }
                  </div>                  
                  <div className='form-item'>
                    {showError ? <InfoMessageComponent text={errorMessage} type={MessageTypes.Error} /> : null}
                  </div>                             
                  <div className="loginButton">
                      <div className="loginBtn" onClick={handleSubmitfunc}>Create an account</div>
                      <h4 className="terms">By confirming your email, you agree to our <a href="https://rocketdesert.com/terms-of-service" target="_blank" rel="noopener noreferrer" className="anchor color-primary">Terms of Service</a> and that you have read and understood our <a href="https://rocketdesert.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="anchor color-primary">Privacy Policy</a>.</h4>
                  </div> </form> </>  
                    : <InfoMessageComponent text='Your company account has been successfully registered on our platform.' type={MessageTypes.Success} /> }
                    <div className="getAccess">
                          <p>already have an account? <a href="/">Sign In</a></p>
                  </div>
              </div>             
              
                
              
                
              
              
    </IndexLayoutPage>     

   
  );
}

export default CreateAccountPage;
