import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {getUsers} from "../../services/user.service";
import { convertDate } from "../../utils/date";
import EmployeeTableIteam from "./employee-table-items.feature";
import EmployeeTable from "./employee-table.feature";
import { TabsEnum } from '../boarding/onboarding/onboarding-table.feature';
import SpinnerWrapper from "../../components/Spinner/SpinnerWrapper.component";
interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
}

const EmployeeFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyWord] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    const [users, setUsers] = useState<any[]>([]);

    const handleSendData = async (data: string)=> {
        setSearchKeyWord(data);
        const employees = await getUsers(searchKeyword);
        setUsers(employees.users);
    }

    const fetchEmployees = async () => {
        setIsLoading(true);
        const employees = await getUsers(searchKeyword);
        setUsers(employees.users);
        setIsLoading(false);
    }

    useEffect(()=> {
        async function getChapterList() {
            setIsLoading(true);
            const employees = await getUsers(searchKeyword);
            setUsers(employees.users);
            setIsLoading(false);
        }
        getChapterList();
    }, [searchKeyword]);

    return <>
    {isLoading ? <SpinnerWrapper /> :
        <EmployeeTable
            title = { title }
            showControls = { showControls }
            showTitle = { showTitle }
            showSearch = { showSearch }
            tabType = { tabType }
            sendDataToParent={handleSendData}
            fetchEmployees={ fetchEmployees }
            fetchOnboardings={fetchEmployees}
        >
            {users.length ? users.map((item, i)=> {
                return <EmployeeTableIteam onClick = { ()=> handleNavigate(`${item.id}/view`)}
                    id={i + 1}
                    key={i + 1}
                    position={item.position? item.position.name : 'No position chosen' }
                    firstName={item.firstName}
                    lastName={item.lastName}
                    chapterLead = { item.isChapterLead ? "Yes" : 'No' }
                    team = {item.team ? item.team.name : 'Team is not chosen'}
                    status = {item.status.toUpperCase()}
                    createdAt={convertDate(item.createdAt)}
                ></EmployeeTableIteam>
            }) : null }
        </EmployeeTable>
    }
    </>
}

export default EmployeeFeature;
