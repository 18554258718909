import styled from "styled-components"

const Button = styled.div<{bgColor?: string, txtColor?: string, border?: string}>`
    min-width: 100px;
    padding: 13px;
    border-radius: 7px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    border: ${props => props.border ? props.border : 'none'};
    background-color: ${props => props.bgColor ? props.bgColor : '#e3e1e1'};
    color: ${props => props.txtColor ? props.txtColor : '#3b3a39'};
`;

interface Props{
    text: string;
    color?: string;
    border?: string;
    textColor?: string;
    onClick?: React.MouseEventHandler;
 }

const CreateButtonComponent:React.FC<Props> = ({ text, color, textColor, border, onClick })=> (
  <>
    <Button onClick={onClick} border={border} bgColor={color} txtColor={textColor}>
       { text }
    </Button>
  </>
)

export default CreateButtonComponent;