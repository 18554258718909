import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useState } from "react";
import InputTextComponent from "../../../components/input/InputText.component";
import {
  updateWorkflow,
  getWorkflowDetails,
} from "../../../services/workflows.service";
import { getActions } from "../../../services/actions.service";
import LinkButton from "../../../components/LinkButton/LinkButton.component";
import Button from "../../../components/Buttons/Button.component";
import MultiSelectBox from "../../../components/MultiSelectBox/MultiSelectBox.component";

const FormItem = styled.div`
  margin-bottom: 10px;
`;

const Form = styled.div`
  width: 50%;
`;

const FormButtonsItem = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  margin-top: 15px;
`;

function EditWorkflowPage() {
  const [workflowName, setWorkflowName] = useState("");
  const [actionOptions, setActionOptions] = useState<any[]>([]);
  const [assignedActionOptions, setAssignedActionOptions] = useState<any[]>([]);
  const [selectedActionOptions, setSelectedActionOptions] = useState<any[]>([]);

  const navigate = useNavigate();

  let { id } = useParams();
  React.useEffect(() => {
    async function getWorkflow() {
      var workflow = await getWorkflowDetails(id);
      const selectedActionOptionsItems = workflow.actions.map((item) => {
        return { value: item.id, label: item.name };
      });
      setAssignedActionOptions(selectedActionOptionsItems);
      setWorkflowName(workflow.name);
    }
    getWorkflow();
  }, [id]);

  React.useEffect(() => {
    async function getAllActions() {
      var actions = await getActions();
      const actionOptionsItems = actions.map((item) => {
        return { value: item.id, label: item.name };
      });
      setActionOptions(actionOptionsItems);
    }
    getAllActions();
  }, []);

  const handleWorkflowName = (event: any) => {
    setWorkflowName(event.target.value);
  };

  const removeAction = (selectedOptions: any) => {
    setSelectedActionOptions(selectedOptions);
  };

  const selectAction = (selectedOptions: any) => {
    setSelectedActionOptions(selectedOptions);
  };

  const handleSubmit = async () => {
    await updateWorkflow(id, {
      name: workflowName,
      actions: selectedActionOptions.map((item) => item.value),
    });
    navigate("/workflow");
  };

  return (
    <>
      <DashboardLayout>
        <div className="breadcrumbs">
          <div className="breadcrum-gray">Dashboard</div>
          <div className="breadcrum-divider"> / </div>
          <div className="breadcrum-gray">Workflow edit page</div>
        </div>

        
          <Form>
            <FormItem>
              <InputTextComponent
                onChildStateChange={handleWorkflowName}
                value={workflowName}
                fullWidth={true}
                label="Name"
                type="text"
                placeholder="type workflow name"
              />
            </FormItem>
            
            <FormItem>
              <MultiSelectBox
                options={actionOptions}
                selectedOptions={assignedActionOptions}
                onRemove={removeAction}
                onSelect={selectAction} 
                label="Actions"
              />
            </FormItem> 

            <FormButtonsItem>
              <Button
                text="Save"
                color="#2a7aeb"
                onClick={() => handleSubmit()}
              />
              <LinkButton
                onClick={() => navigate("/workflow")}
                text="Cancel"
              ></LinkButton>
            </FormButtonsItem>
          </Form>
        
      </DashboardLayout>
    </>
  );
}

export default EditWorkflowPage;
