import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getWorkflows } from '../../../services/workflows.service';
import WorkflowTableIteam from './workflow-table-items';
import OnboardingTable, { TabsEnum } from './workflow-table.feature';
import { deleteWorkflow } from '../../../services/workflows.service';
import SpinnerWrapper from "../../../components/Spinner/SpinnerWrapper.component";
import {useState} from "react";
import {usePopup} from "../../../hooks/usePopup";
import {Popup} from "../../../components/Popup/popup.component";

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType?: TabsEnum;
}

const WorkflowsFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls, tabType }) => {
    const { isOpen, message, openPopup, closePopup } = usePopup();
    const [workflows, setWorkflows] = React.useState<any[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const workflowsFetch = async () => {
        const workflows = await getWorkflows();
        setWorkflows(workflows.data);
    };

    React.useEffect(() => {
        async function getWorkflowsList() {
            setIsLoading(true);
            const workflows = await getWorkflows();
            setWorkflows(workflows.data);
            setIsLoading(false);
        }
        getWorkflowsList();
    }, []);

    const handleEdit = async (id: string) => {
        navigate('/workflow/edit/' + id);       
    };
    
    const handleDelete = async (id: string) => {
        try {
            await deleteWorkflow(id);
        } catch (error) {
            openPopup(`You can't delete default workflow.`);
        }
     };

    return <>
        {isOpen && <Popup message={message} handleClose={closePopup} />}
        { isLoading ? <SpinnerWrapper /> :
        <OnboardingTable
            title = { title } 
            showControls = { showControls } 
            showTitle = { showTitle } 
            showSearch = { showSearch }
            tabType = { tabType }
            worfklowFetch={workflowsFetch}
        >
            { workflows ? workflows.map((item)=> {
                return  <WorkflowTableIteam
                key = {item.id}
                id={item.id}                               
                name={item.name}
                status={item.status}
                actions={item.actionsCount}
                actionsList = {item.actions}
                createdAt={item.createdAt}               
                onEdit={() => handleEdit(item.id)} 
                onDelete={() => handleDelete(item.id)}
            />
            }) : null }
           
     </OnboardingTable> }
    </>
}

export default WorkflowsFeature;