import styled from "styled-components";
import { useState } from "react";
import SentComponent from "../../../components/Sent/Sent.component";
import InputTextComponent from "../../../components/input/InputText.component";
import { createWorkflow } from "../../../services/workflows.service";
import MultiSelectBox from "../../../components/MultiSelectBox/MultiSelectBox.component";

const FormItem = styled.div`
  margin-bottom: 10px;
`;

const Button = styled.div`
  min-width: 100px;
  max-width: 200px;
  padding: 8px;
  background-color: #2a7aeb;
  border-radius: 4px;
  color: #ffff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
`;

interface InputTextProps {
  title?: string;
  actionsList?: any;
  fetchData?: () => void;
}

const CreateWorkflowFeature: React.FC<InputTextProps> = ({
  actionsList,
  fetchData,
}) => {
  const [notSent, setnotSent] = useState(true);
  const actionsListItems = actionsList.length
    ? actionsList.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];
  const [selectedActionsOption, setSelectedActionsOption] = useState<any[]>([]);
  const [workflowName, setWorkflowName] = useState("");

  const removeAction = (selectedOptions: any) => {
    setSelectedActionsOption(selectedOptions);
  };

  const selectAction = (selectedOptions: any) => {
    setSelectedActionsOption(selectedOptions);
  };

  const handleWorflowName = (event: any) => {
    setWorkflowName(event.target.value);
  };

  const handleSubmit = async () => {
    await createWorkflow({
      name: workflowName,
      actions: selectedActionsOption.map((item) => item.value),
    });
    fetchData && fetchData();
    setnotSent(false);
  };

  return (
    <>
      {notSent ? (
        <div>
          <FormItem>
            <InputTextComponent
              onChildStateChange={handleWorflowName}
              fullWidth={true}
              label="Workflow name"
              type="text"
              placeholder="Workflow name"
            />
          </FormItem>
          <FormItem>
            <MultiSelectBox
              options={actionsListItems}
              selectedOptions={selectedActionsOption}
              onRemove={removeAction}
              onSelect={selectAction}
            />
          </FormItem>
          <FormItem>
            <Button onClick={() => handleSubmit()}>Create Workflow</Button>
          </FormItem>
        </div>
      ) : (
        <SentComponent title="Workflow has been created!" />
      )}
    </>
  );
};

export default CreateWorkflowFeature;
