import { useState } from "react";

export const usePopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");

    const openPopup = (msg) => {
        setMessage(msg);
        setIsOpen(true);
    };

    const closePopup = () => setIsOpen(false);

    return { isOpen, message, openPopup, closePopup };
};