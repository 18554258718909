import { apiClient } from "../infrastructure/api/apiClient";

export const getWorkflowItems =  (onboardingId: string) => {
    return  apiClient.get(`/task/workflow/${onboardingId}`);
}

export const getOffboardingWorkflowItems =  (onboardingId: string) => {
    return  apiClient.get(`/task/workflow/offboarding/${onboardingId}`);
}

export const getActionsByEmployeeId =  (employeeId: string) => {
    return  apiClient.get(`/task/workflow/tasks/${employeeId}`);
}

export const createTask = (payload: any, boardingId: string) => {
    return apiClient.post(`/task/assign-task/${boardingId}`, payload);
};

export const createEmployeeTask = (payload: any, boardingId: string) => {
    return apiClient.post(`/task/assign-employee-task/${boardingId}`, payload);
};

export const removeAssignedTask = (taskId: string) => {
    return apiClient.delete(`/task/remove/${taskId}`);
};
