import styled from 'styled-components';
import './styles/tab-style.css';

const BadgeSpan = styled.span `
  background-color: #2a7aeb;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
`

const TabComponent = ({
    title = '',
    onItemClicked = () => console.error('You passed no action to the component'),
    isActive = false,
    badge = null
  }) => {
    return (
      <div className={isActive ? 'tabitem' : 'tabitem tabitem--inactive'} onClick={onItemClicked}>
        <p className="tabitem__title">{badge ? <BadgeSpan>{badge}</BadgeSpan>: null} {title} </p>
      </div>
    )
};

export default TabComponent;